<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import AppMenuItem from './AppMenuItem.vue';

const store = useStore();

const isBusiness = computed(() => {
  const { userType } = store.getters['users/userInfo'];
  return userType === 'Business';
});

const isInvestigation = computed(() => {
  const { userType } = store.getters['users/userInfo'];
  return userType === 'Investigation';
});

const isCredit = computed(() => {
  const { userType } = store.getters['users/userInfo'];
  return userType === 'Credit';
});

const isRisk = computed(() => {
  const { userType } = store.getters['users/userInfo'];
  return userType === 'Risk';
});

const isAdmin = computed(() => {
  const { userType } = store.getters['users/userInfo'];
  return userType === 'Admin';
});

const isMicro = computed(() => {
  const { userType } = store.getters['users/userInfo'];
  return userType === 'Micro';
});

const businessMenu = ref([
  {
    label: 'home',
    items: [{ label: 'dashboard', icon: 'pi pi-fw pi-home', to: '/' }],
  },
  {
    label: 'customer',
    items: [
      {
        label: 'newCustomers',
        icon: 'pi pi-fw pi-users',
        to: '/customers/add-customer/',
      },
      { label: 'customersList', icon: 'pi pi-fw pi-id-card', to: '/customers/customer-list/' },
    ],
  },
]);
const investigationMenu = ref([
  {
    label: 'home',
    items: [{ label: 'dashboard', icon: 'pi pi-fw pi-home', to: '/' }],
  },
  {
    label: 'customer',
    items: [
      { label: 'customersList', icon: 'pi pi-fw pi-id-card', to: '/customers/customer-list/' },
      {
        label: 'investigationList',
        icon: 'pi pi-search',
        to: '/investigations-list/',
      },
    ],
  },
]);
const creditMenu = ref([
  {
    label: 'home',
    items: [{ label: 'dashboard', icon: 'pi pi-fw pi-home', to: '/' }],
  },
  {
    label: 'customer',
    items: [
      {
        label: 'investigationList',
        icon: 'pi pi-search',
        to: '/investigations-list/',
      },
      {
        label: 'creditList',
        icon: 'pi pi-credit-card',
        to: '/credit-list/',
      },
    ],
  },

  {
    label: 'reportingCenter',
    items: [{ label: 'temporaryReport', icon: 'pi pi-chart-bar', to: '/report-center' }],
  },
]);
const riskMenu = ref([
  {
    label: 'home',
    items: [{ label: 'dashboard', icon: 'pi pi-fw pi-home', to: '/' }],
  },
  {
    label: 'customer',
    items: [
      {
        label: 'investigationList',
        icon: 'pi pi-search',
        to: '/investigations-list/',
      },

      {
        label: 'riskList',
        icon: 'pi pi-exclamation-triangle',
        to: '/risk-list/',
      },
    ],
  },

  {
    label: 'reportingCenter',
    items: [{ label: 'temporaryReport', icon: 'pi pi-chart-bar', to: '/report-center' }],
  },
]);

const adminMenu = ref([
  {
    label: 'home',
    items: [
      {
        label: 'dashboard',
        icon: 'pi pi-fw pi-home',
        to: '/micro',
      },
    ],
  },
  {
    label: 'customer',
    items: [
      {
        label: 'newCustomers',
        icon: 'pi pi-fw pi-users',
        to: '/customers/add-customer/',
      },
      {
        label: 'customersList',
        icon: 'pi pi-fw pi-id-card',
        to: '/customers/customer-list/',
      },
      {
        label: 'investigationList',
        icon: 'pi pi-search',
        to: '/investigations-list/',
      },
      { label: 'creditList', icon: 'pi pi-credit-card', to: '/credit-list/' },
      { label: 'riskList', icon: 'pi pi-exclamation-triangle', to: '/risk-list/' },
      { label: 'committeeList', icon: 'pi pi-key', to: '/committee-list/' },
    ],
  },
  {
    label: 'managment',
    items: [
      { label: 'requestMonitor', icon: 'pi pi-desktop', to: '/request-monitor/' },
      { label: 'timeline', icon: 'pi pi-spin pi-history', to: '/timeline/' },
    ],
  },
  {
    label: 'reportingCenter',
    items: [
      { label: 'temporaryReport', icon: 'pi pi-chart-bar', to: '/report-center' },
      { label: 'mainReport', icon: 'pi pi-chart-bar', to: '/main-report' },
      { label: 'detailedReport', icon: 'pi pi-chart-bar', to: '/detailed-report' },
      { label: 'industryConcentrationReport', icon: 'pi pi-chart-bar', to: '/industry-concentration-report' },
    ],
  },
  {
    label: 'charts',
    items: [
      {
        label: 'reportCharts',
        icon: 'pi pi-chart-bar',
        to: '/charts',
      },
    ],
  },
]);

const microMenu = ref([
  {
    label: 'home',
    items: [{ label: 'dashboard', icon: 'pi pi-fw pi-home', to: '/micro' }],
  },
  {
    label: 'customer',
    items: [
      {
        label: 'newCustomers',
        icon: 'pi pi-fw pi-users',
        to: '/customers/micro-add-customer/',
      },
      {
        label: 'customersList',
        icon: 'pi pi-fw pi-id-card',
        to: '/customers/micro-customer-list/',
      },
      {
        label: 'addRequest',
        icon: 'pi pi-fw pi-id-card',
        to: '/customers/new-micro-request/',
      },
      {
        label: 'incomeStatement',
        icon: 'pi pi-fw pi-dollar',
        to: '/micro-income-statement/',
      },
      {
        label: 'cashflowStatement',
        icon: 'pi pi-fw pi-money-bill',
        to: '/cashflow-statement/',
      },
      {
        label: 'microCreditForm',
        icon: ' pi pi-credit-card',
        to: '/micro-credit-details/',
      },
      {
        label: 'guarantees',
        icon: 'pi pi-save',
        to: '/micro-guarantee/:investigationId/',
      },
      {
        label: 'facilities',
        icon: ' pi pi-wrench',
        to: '/micro-facilities-details/:investigationId/',
      },
    ],
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <div v-if="isBusiness">
      <template v-for="(item, i) in businessMenu" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </div>
    <div v-if="isInvestigation">
      <template v-for="(item, i) in investigationMenu" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </div>
    <div v-if="isCredit">
      <template v-for="(item, i) in creditMenu" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </div>
    <div v-if="isRisk">
      <template v-for="(item, i) in riskMenu" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </div>
    <div v-if="isAdmin">
      <template v-for="(item, i) in adminMenu" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </div>
    <div v-if="isMicro">
      <template v-for="(item, i) in microMenu" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </div>
  </ul>
</template>
