import axios from 'axios';

export default {
  namespaced: true,
  state: {
    maritalStatusOptions: [],
    educationOptions: [],
    legalStructureOptions: [],
    loanPurpose: [
      { key: 'workingCapitalLoan', value: 'workingCapitalLoan' },
      { key: 'assetFinanceLoan', value: 'assetFinanceLoan' },
      { key: 'others', value: 'others' },
    ],
    loansType: [
      { key: 'personal', value: 'personalLoan' },
      { key: 'home', value: 'homeLoan' },
      { key: 'auto', value: 'autoLoan' },
      { key: 'student', value: 'studentLoan' },
      { key: 'business', value: 'businessLoan' },
      { key: 'payday', value: 'paydayLoan' },
      { key: 'mortgage', value: 'mortgageLoan' },
    ],
    currencies: [
      { key: 'EGP', value: 'egyptianPound' },
      { key: 'USD', value: 'unitedStatesDollar' },
      { key: 'EUR', value: 'euro' },
      { key: 'GBP', value: 'britishPoundSterling' },
      { key: 'JPY', value: 'japaneseYen' },
      { key: 'AUD', value: 'australianDollar' },
      { key: 'CAD', value: 'canadianDollar' },
      { key: 'CHF', value: 'swissFranc' },
      { key: 'CNY', value: 'chineseYuan' },
      { key: 'INR', value: 'indianRupee' },
      { key: 'BRL', value: 'brazilianReal' },
    ],
    interestRateType: [
      { key: 'fixed', value: 'fixed' },
      { key: 'variable', value: 'variable' },
    ],
    repaymentFrequencyType: [
      { key: 'M', value: 'monthly' },
      { key: 'Q', value: 'quarterly' },
      { key: 'A', value: 'annually' },
    ],
    industryOptions: [],
    subIndustryOptions: [],
    headquarterOwnershipOptions: [],
    housingOwnershipOptions: [],
    managerRelationOptions: [],
    branchOptions: [],
    currencyOptions: [],
    assetTypeOptions: [],
    assetManufactureOptions: [],
    supplierTypeOptions: [],
    ownershipDocumentationTypeOptions: [],
    speedConversionToCashOptions: [],
    assetValueChangingOptions: [],
    assetConditionOptions: [],
    governorateOptions: [],
    directorateOptions: [],
    districtOptions: [],
    creditCommitteeMembers: [],
    executiveCommitteeMembers: [],
    creditTeamMembers: [],
    riskTeamMembers: [],
    sex: ['Male', 'Female'],
    idType: ['passport', 'nationalID'],
    currency: [],
    financingTerm: [
      'One year', 'Two years', 'Three years', 'Four years', 'Five years',
      'Six years', 'Seven years', 'Eight years', 'Nine years', 'Ten years',
    ],
    tearmsInMonths: {
      12: 'Monthly',
      4: 'Quarterly',
      2: 'Semi Annual',
    },
    leaseRentType: {
      0: 'In Arrear',
      1: 'In Advance',
    },
    yearMonths: {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    },
    countryList: {
      AF: 'AFGHANISTAN',
      AL: 'ALBANIA',
      DZ: 'ALGERIA',
      AS: 'AMERICAN SAMOA',
      AD: 'ANDORRA',
      AO: 'ANGOLA',
      AR: 'ARGENTINA',
      AM: 'ARMENIA',
      AW: 'ARUBA',
      AU: 'AUSTRALIA',
      AT: 'AUSTRIA',
      AZ: 'AZERBAIJAN',
      BS: 'BAHAMAS',
      BH: 'BAHRAIN',
      BD: 'BANGLADESH',
      BB: 'BARBADOS',
      BY: 'BELARUS',
      BE: 'BELGIUM',
      BZ: 'BELIZE',
      BJ: 'BENIN',
      BM: 'BERMUDA',
      BT: 'BHUTAN',
      BO: 'BOLIVIA',
      BA: 'BOSNIA',
      BW: 'BOTSWANA',
      BV: 'BOUVET ISLAND',
      BR: 'BRAZIL',
      BG: 'BULGARIA',
      BF: 'BURKINA FASO',
      BI: 'BURUNDI',
      KH: 'CAMBODIA',
      CM: 'CAMEROON',
      CA: 'CANADA',
      CV: 'CAPE VERDE',
      KY: 'CAYMAN ISLANDS',
      TD: 'CHAD',
      CL: 'CHILE',
      CN: 'CHINA',
      CX: 'CHRISTMAS ISLAND',
      CO: 'COLOMBIA',
      KM: 'COMOROS',
      CG: 'CONGO',
      CR: 'COSTA RICA',
      CI: 'CÔTE D\'IVOIRE',
      HR: 'CROATIA',
      CU: 'CUBA',
      CY: 'CYPRUS',
      CZ: 'CZECH REPUBLIC',
      DK: 'DENMARK',
      DJ: 'DJIBOUTI',
      DM: 'DOMINICA',
      DO: 'DOMINICAN REPUBLIC',
      EC: 'ECUADOR',
      EG: 'EGYPT',
      SV: 'EL SALVADOR',
      GQ: 'EQUATORIAL GUINEA',
      ER: 'ERITREA',
      EE: 'ESTONIA',
      ET: 'ETHIOPIA',
      FK: 'FALKLAND ISLANDS (MALVINAS)',
      FO: 'FAROE ISLANDS',
      FJ: 'FIJI',
      FI: 'FINLAND',
      FR: 'FRANCE',
      GF: 'FRENCH GUIANA',
      GA: 'GABON',
      GM: 'GAMBIA',
      GE: 'GEORGIA',
      DE: 'GERMANY',
      GH: 'GHANA',
      GI: 'GIBRALTAR',
      GR: 'GREECE',
      GL: 'GREENLAND',
      GD: 'GRENADA',
      GP: 'GUADELOUPE',
      GU: 'GUAM',
      GT: 'GUATEMALA',
      GN: 'GUINEA',
      GY: 'GUYANA',
      HT: 'HAITI',
      HN: 'HONDURAS',
      HK: 'HONG KONG',
      HU: 'HUNGARY',
      IS: 'ICELAND',
      IN: 'INDIA',
      ID: 'INDONESIA',
      IR: 'IRAN',
      IQ: 'IRAQ',
      IE: 'IRELAND',
      IL: 'ISRAEL',
      IT: 'ITALY',
      JM: 'JAMAICA',
      JP: 'JAPAN',
      JO: 'JORDAN',
      KW: 'KUWAIT',
      KZ: 'KAZAKHSTAN',
      KE: 'KENYA',
      KI: 'KIRIBATI',
      KP: 'KOREA',
      KG: 'KYRGYZSTAN',
      LV: 'LATVIA',
      LB: 'LEBANON',
      LS: 'LESOTHO',
      LR: 'LIBERIA',
      LY: 'LIBYA',
      LI: 'LIECHTENSTEIN',
      LT: 'LITHUANIA',
      LU: 'LUXEMBOURG',
      MO: 'MACAO',
      MK: 'MACEDONIA',
      MG: 'MADAGASCAR',
      MW: 'MALAWI',
      MY: 'MALAYSIA',
      MV: 'MALDIVES',
      ML: 'MALI',
      MT: 'MALTA',
      MQ: 'MARTINIQUE',
      MR: 'MAURITANIA',
      MU: 'MAURITIUS',
      YT: 'MAYOTTE',
      MX: 'MEXICO',
      FM: 'MICRONESIA',
      MD: 'MOLDOVA',
      MC: 'MONACO',
      MN: 'MONGOLIA',
      ME: 'MONTENEGRO',
      MS: 'MONTSERRAT',
      MA: 'MOROCCO',
      MZ: 'MOZAMBIQUE',
      MM: 'MYANMAR',
      NA: 'NAMIBIA',
      NR: 'NAURU',
      NP: 'NEPAL',
      NL: 'NETHERLANDS',
      NC: 'NEW CALEDONIA',
      NZ: 'NEW ZEALAND',
      NI: 'NICARAGUA',
      NE: 'NIGER',
      NG: 'NIGERIA',
      NU: 'NIUE',
      NF: 'NORFOLK ISLAND',
      NO: 'NORWAY',
      OM: 'OMAN',
      PK: 'PAKISTAN',
      PW: 'PALAU',
      PS: 'PALESTINE',
      PA: 'PANAMA',
      PY: 'PARAGUAY',
      PE: 'PERU',
      PH: 'PHILIPPINES',
      PN: 'PITCAIRN',
      PL: 'POLAND',
      PT: 'PORTUGAL',
      PR: 'PUERTO RICO',
      QA: 'QATAR',
      RE: 'RÉUNION',
      RO: 'ROMANIA',
      RU: 'RUSSIAN FEDERATION',
      RW: 'RWANDA',
      SH: 'SAINT HELENA',
      LC: 'SAINT LUCIA',
      WS: 'SAMOA',
      SM: 'SAN MARINO',
      SA: 'SAUDI ARABIA',
      SN: 'SENEGAL',
      RS: 'SERBIA',
      SC: 'SEYCHELLES',
      SL: 'SIERRA LEONE',
      SG: 'SINGAPORE',
      SK: 'SLOVAKIA',
      SI: 'SLOVENIA',
      SB: 'SOLOMON ISLANDS',
      SO: 'SOMALIA',
      ZA: 'SOUTH AFRICA',
      SS: 'SOUTH SUDAN',
      ES: 'SPAIN',
      LK: 'SRI LANKA',
      SD: 'SUDAN',
      SR: 'SURINAME',
      SZ: 'SWAZILAND',
      SE: 'SWEDEN',
      CH: 'SWITZERLAND',
      SY: 'SYRIAN ARAB REPUBLIC',
      TW: 'TAIWAN',
      TJ: 'TAJIKISTAN',
      TZ: 'TANZANIA',
      TH: 'THAILAND',
      TL: 'TIMOR-LESTE',
      TG: 'TOGO',
      TK: 'TOKELAU',
      TO: 'TONGA',
      TT: 'TRINIDAD AND TOBAGO',
      TN: 'TUNISIA',
      TR: 'TURKEY',
      TM: 'TURKMENISTAN',
      TV: 'TUVALU',
      UG: 'UGANDA',
      UA: 'UKRAINE',
      AE: 'UNITED ARAB EMIRATES',
      GB: 'UNITED KINGDOM',
      US: 'UNITED STATES',
      UY: 'URUGUAY',
      UZ: 'UZBEKISTAN',
      VU: 'VANUATU',
      VE: 'VENEZUELA',
      VN: 'VIET NAM',
      YE: 'YEMEN',
      ZM: 'ZAMBIA',
      ZW: 'ZIMBABWE',
    },
    typeOfInsurance: [
      'Theft', 'Fire', 'Damage', 'All Of Them',
    ],
    sectorOptions: [],
    facilityPurposes: [],
    typeOfFacility: [
      'Direct leasing', 'Sale with leaseback',
    ],
    facilityStatusOptions: [],
    typeOfInstallment: [
      'Monthly in advanced', 'Monthly in arrears', 'Quarterly in advanced',
      'Quarterly in arrears', 'Semi annual in advanced', 'Semi annual in arrears',
    ],
    typeOfRequest: [],
    banks: [],
    guaranteeType: ['Customer', 'Guarantor'],
    creditFacilities: [],
    customerType: ['Local', 'Export'],
    incomeStatementHeader: [
      {
        key: 'net_sales', value: 'Net Sales', calculated: false, negative: false, positive: false,
      },
      {
        key: 'cost_of_goods_sold', value: 'Cost Of Goods Sold (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'depreciation', value: 'Depreciation (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'gross_profit', value: 'Gross Profit', calculated: true, negative: false, positive: false,
      },
      {
        key: 'admin_depreciation', value: 'Admin Depreciation (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'sales_general_admin_expense', value: 'Sales, General &Admin Expense (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'provisions', value: 'Provisions (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'research_development_expenses', value: 'Research & Development Expenses (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'goodwill_amortization', value: 'Goodwill Amortization (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'other_amortization', value: 'Other Amortization (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'net_operating_profit', value: 'Net Operating Profit', calculated: true, negative: false, positive: false,
      },
      {
        key: 'gross_interest_exp_exist', value: 'Gross Interest Exp--Exist (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'interest_expense_new', value: 'Interest Exp--New (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'interest_income', value: 'Interest Income', calculated: false, negative: false, positive: false,
      },
      {
        key: 'divided_income', value: 'Dividend Income', calculated: false, negative: false, positive: false,
      },
      {
        key: 'sundry_income_expense_fx_exchange', value: 'Sundry Income (Expense) Fx Exchange', calculated: false, negative: false, positive: false,
      },
      {
        key: 'sundry_income_expense', value: 'Sundry Income (Expense)', calculated: false, negative: false, positive: false,
      },
      {
        key: 'net_profit_before_tax', value: 'Net Profit Before Tax', calculated: true, negative: false, positive: false,
      },
      {
        key: 'total_tax_expense', value: 'Total Tax Expense', calculated: true, negative: false, positive: false,
      },
      {
        key: 'current_tax_expense', value: 'Current Tax Expense (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'deferred_tax_expense', value: 'Deferred Tax Expense', calculated: false, negative: false, positive: false,
      },
      {
        key: 'net_profit_after_tax', value: 'Net Profit After Tax', calculated: true, negative: false, positive: false,
      },
      {
        key: 'sale_of_plant', value: 'Sale Of Plant', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other_unusual_item', value: 'Other Unusual Item', calculated: false, negative: false, positive: false,
      },
      {
        key: 'net_profit_after_unusual_items', value: 'Net Profit After Unusual Items (Npaui)', calculated: true, negative: false, positive: false,
      },
      {
        key: 'common_stock_dividends', value: 'Common Stock Dividends (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'other_adjustment', value: 'Other Adjustment', calculated: false, negative: false, positive: true,
      },
      {
        key: 'total_change_in_re', value: 'Total Change In R/E', calculated: true, negative: false, positive: false,
      },
    ],
    liabEquityHeader: [
      {
        key: 'short_term_borrowing_existing', value: 'Short-term borrowing existing', calculated: false, negative: false, positive: false,
      },
      {
        key: 'short_term_borrowing_new', value: 'Short-term borrowing new', calculated: false, negative: false, positive: false,
      },
      {
        key: 'current_portion_senior_debt_existing', value: 'Current portion senior debt existing', calculated: false, negative: false, positive: false,
      },
      {
        key: 'accounts_payable', value: 'Accounts Payable', calculated: false, negative: false, positive: false,
      },
      {
        key: 'taxes_payable', value: 'Taxes Payable', calculated: false, negative: false, positive: false,
      },
      {
        key: 'accrued_expenses', value: 'Accrued Expenses', calculated: false, negative: false, positive: false,
      },
      {
        key: 'interest_payable', value: 'Interest Payable', calculated: false, negative: false, positive: false,
      },
      {
        key: 'dividends_payable', value: 'Dividends Payable', calculated: false, negative: false, positive: false,
      },
      {
        key: 'down_payments', value: 'Down Payments', calculated: false, negative: false, positive: false,
      },
      {
        key: 'due_to_sister_companies', value: 'Due to sister companies', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other_current_liability', value: 'Other current liability', calculated: false, negative: false, positive: false,
      },
      {
        key: 'total_current_liabilities', value: 'Total current liabilities', calculated: true, negative: false, positive: false,
      },
      {
        key: 'long_term_lease_obligation', value: 'Long-term lease obligation', calculated: false, negative: false, positive: false,
      },
      {
        key: 'long_term_lease_existing', value: 'Long-term lease existing', calculated: false, negative: false, positive: false,
      },
      {
        key: 'long_term_senior_debt_new', value: 'Long-term senior debt new', calculated: false, negative: false, positive: false,
      },
      {
        key: 'long_term_sub_debt_existing', value: 'Long-term sub debt existing', calculated: false, negative: false, positive: false,
      },
      {
        key: 'long_term_sub_debt_new', value: 'Long-term sub debt new', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other_non_current_liabilities', value: 'Other non current liabilities', calculated: false, negative: false, positive: false,
      },
      {
        key: 'due_to_owners', value: 'Due to owners', calculated: false, negative: false, positive: false,
      },
      {
        key: 'provisions', value: 'Provisions', calculated: false, negative: false, positive: false,
      },
      {
        key: 'total_long_term_debt', value: 'Total Long-term debt', calculated: true, negative: false, positive: false,
      },
      {
        key: 'total_liabilities', value: 'Total liabilities', calculated: true, negative: false, positive: false,
      },
      {
        key: 'deferred_taxes', value: 'Deferred Taxes', calculated: false, negative: false, positive: false,
      },
      {
        key: 'minority_interest', value: 'Minority Interest', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other_grey_area_item', value: 'Other grey area item', calculated: false, negative: false, positive: false,
      },
      {
        key: 'total_grey_area', value: 'Total grey area', calculated: true, negative: false, positive: false,
      },
      {
        key: 'preferred_stock', value: 'Preferred Stock', calculated: false, negative: false, positive: false,
      },
      {
        key: 'common_stock', value: 'Common Stock', calculated: false, negative: false, positive: false,
      },
      {
        key: 'additional_paid_in_capital', value: 'Additional paid in capital', calculated: false, negative: false, positive: false,
      },
      {
        key: 'retained_earnings_opening', value: 'Retained earnings opening', calculated: false, negative: false, positive: false,
      },
      {
        key: 'net_income', value: 'Net Income', calculated: true, negative: false, positive: false,
      },
      {
        key: 'dividends', value: 'Dividends', calculated: false, negative: false, positive: false,
      },
      {
        key: 'retained_earnings_closing', value: 'Retained Earnings Closing', calculated: true, negative: false, positive: false,
      },
      {
        key: 'ongoing_owners_account', value: 'Ongoing Owners Account', calculated: false, negative: false, positive: false,
      },
      {
        key: 'legal_reverse', value: 'Legal Reverse', calculated: false, negative: false, positive: false,
      },
      {
        key: 'fx_adjustment', value: 'FX Adjustment', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other_equity_reverses', value: 'Other Equity Reverses', calculated: false, negative: false, positive: false,
      },
      {
        key: 'total_equity', value: 'Total Equity', calculated: true, negative: false, positive: false,
      },
      {
        key: 'total_liab_and_equity', value: 'Total Liab & Equity', calculated: true, negative: false, positive: false,
      },
      {
        key: 'reconcile_bs', value: 'Reconcile BS', calculated: true, negative: false, positive: false,
      },
      {
        key: 'new_money_need_short', value: 'New Money Need Short', calculated: false, negative: false, positive: false,
      },
      {
        key: 'new_money_need_long', value: 'New Money Need Long', calculated: false, negative: false, positive: false,
      },
    ],
    assetsHeader: [
      {
        key: 'cash_and_cash_equivalent', value: 'Cash & Cash Equivalent', calculated: false, negative: false, positive: true,
      },
      {
        key: 'marketable_securities', value: 'Marketable Securities', calculated: false, negative: false, positive: true,
      },
      {
        key: 'account_receivables', value: 'Account Receivables', calculated: false, negative: false, positive: true,
      },
      {
        key: 'allowances', value: 'Allowances (-)', calculated: false, negative: true, positive: false,
      },
      {
        key: 'net_account_receivables', value: 'Net Account Receivables', calculated: true, negative: false, positive: false,
      },
      {
        key: 'raw_materials', value: 'Raw Materials', calculated: false, negative: false, positive: true,
      },
      {
        key: 'work_in_progress', value: 'Work in Progress', calculated: false, negative: false, positive: true,
      },
      {
        key: 'finished_goods', value: 'Finished Goods', calculated: false, negative: false, positive: true,
      },
      {
        key: 'goods_in_transit', value: 'Goods in Transit', calculated: false, negative: false, positive: true,
      },
      {
        key: 'total_inventory', value: 'Total Inventory', calculated: true, negative: false, positive: false,
      },
      {
        key: 'advance_payments_plus_prepaids', value: 'Advance Payments Plus Prepaids', calculated: false, negative: false, positive: true,
      },
      {
        key: 'current_due_from_shareholders', value: 'Current due from Shareholders', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other_current_asset', value: 'Other Current Asset', calculated: false, negative: false, positive: true,
      },
      {
        key: 'total_current_assets', value: 'Total Current Assets', calculated: true, negative: false, positive: false,
      },
      {
        key: 'land', value: 'Land', calculated: false, negative: false, positive: true,
      },
      {
        key: 'building_and_improvement', value: 'Building & Improvement', calculated: false, negative: false, positive: true,
      },
      {
        key: 'equipment', value: 'Equipment', calculated: false, negative: false, positive: true,
      },
      {
        key: 'construction_in_progress', value: 'Construction in Progress', calculated: false, negative: false, positive: false,
      },
      {
        key: 'other', value: 'Other', calculated: false, negative: false, positive: true,
      },
      {
        key: 'gross_plant', value: 'Gross Plant', calculated: true, negative: false, positive: false,
      },
      {
        key: 'less_accumulated_dep', value: 'Less Accumulated dep (-)', calculated: false, negative: false, positive: false,
      },
      {
        key: 'net_property_plant_equipment', value: 'Net Property Plant Equipment', calculated: true, negative: false, positive: false,
      },
      {
        key: 'investments', value: 'Investments', calculated: false, negative: false, positive: true,
      },
      {
        key: 'due_from_shareholders', value: 'Due from Shareholders', calculated: false, negative: false, positive: true,
      },
      {
        key: 'other_tangible_asset', value: 'Other Tangible Asset', calculated: false, negative: false, positive: true,
      },
      {
        key: 'deferred_tax_asset', value: 'Deferred Tax Asset', calculated: false, negative: false, positive: true,
      },
      {
        key: 'long_term_prepaids', value: 'Long Term Prepaids', calculated: false, negative: false, positive: true,
      },
      {
        key: 'total_other_tangible_assets', value: 'Total Other Tangible Assets', calculated: true, negative: false, positive: false,
      },
      {
        key: 'goodwill', value: 'Goodwill', calculated: false, negative: false, positive: true,
      },
      {
        key: 'less_accum_amortization', value: 'Less Accum Amortization', calculated: false, negative: true, positive: false,
      },
      {
        key: 'net_intangibles', value: 'Net Intangibles', calculated: true, negative: false, positive: false,
      },
      {
        key: 'total_fixed_assets', value: 'Total Fixed Assets', calculated: true, negative: false, positive: false,
      },
      {
        key: 'total_assets', value: 'Total Assets', calculated: true, negative: false, positive: false,
      },
    ],
    cashFlowHeader: [
      {
        key: 'npaui', value: 'Npaui', isHeader: false,
      },
      {
        key: 'interest_income', value: 'Interest Income', isHeader: false,
      },
      {
        key: 'divided_income', value: 'Dividend Income', isHeader: false,
      },
      {
        key: 'sundry_income_expense_fx_exchange', value: 'Sundry Income Expense Fx Exchange', isHeader: false,
      },
      {
        key: 'sundry_income_expense', value: 'Sundry Income Expense', isHeader: false,
      },
      {
        key: 'interest_expense', value: 'Interest Expense', isHeader: false,
      },
      {
        key: 'gain_loss_on_sale_of_plant', value: 'Gain Loss On Sale Of Plant', isHeader: false,
      },
      {
        key: 'other_unusual_item', value: 'Other Unusual Item ', isHeader: false,
      },
      {
        key: 'tax_payable', value: 'Taxes Payable', isHeader: false,
      },
      {
        key: 'tax_paid', value: 'Taxes Paid', isHeader: false,
      },
      {
        key: 'nopat', value: 'Nopat', isHeader: true,
      },
      {
        key: 'goodwill_and_other_amort', value: 'Goodwill And Other Amort', isHeader: false,
      },
      {
        key: 'depreciation', value: ' Depreciation', isHeader: false,
      },
      {
        key: 'copat', value: 'Copat', isHeader: true,
      },
      {
        key: 'change_in_account_receivables', value: 'Change In Account Receivables', isHeader: false,
      },
      {
        key: 'change_in_inventory', value: 'Change In Inventory', isHeader: false,
      },
      {
        key: 'advance_payments_and_prepaids', value: 'Advance Payments+ Prepaids', isHeader: false,
      },
      {
        key: 'change_in_account_payables', value: 'Change In Account Payables', isHeader: false,
      },
      {
        key: 'change_accrued_expenses', value: 'Change Accrued Expenses', isHeader: false,
      },
      {
        key: 'change_in_downpayments', value: 'Change In Down Payments', isHeader: false,
      },
      {
        key: 'net_change_in_working_investment', value: 'Net Change in Working Investment', isHeader: true,
      },
      {
        key: 'change_in_marketable_securities', value: 'Change In Marketable Securities', isHeader: false,
      },
      {
        key: 'other_current_asset', value: 'Other Current Asset', isHeader: false,
      },
      {
        key: 'change_in_interest_payable', value: 'Change In Interest Payable', isHeader: false,
      },
      {
        key: 'due_from_shareholders', value: 'Due From Shareholders', isHeader: false,
      },
      {
        key: 'other_current_liability_and_due_from_shareholders', value: 'Other Current Liability Due From Shareholders', isHeader: false,
      },
      {
        key: 'cash_after_curr_operations', value: 'Cash After Curr Operations', isHeader: true,
      },
      {
        key: 'interest_expense_neg', value: 'Interest Expense', isHeader: false,
      },
      {
        key: 'current_portion_of_long_term_debt_existing', value: 'Current Portion Of Long Term Debt Existing', isHeader: false,
      },
      {
        key: 'short_term_loan', value: 'Short Term Loan', isHeader: false,
      },
      {
        key: 'total_financing_payments', value: 'Total Financing Payments', isHeader: true,
      },
      {
        key: 'cash_before_lt_uses', value: 'Cash Before lt Uses', isHeader: true,
      },
      {
        key: 'net_plant_expenditures', value: 'Net Plant Expenditures', isHeader: false,
      },
      {
        key: 'interest_income_reverse', value: 'Interest Income', isHeader: false,
      },
      {
        key: 'divided_income_reverse', value: 'Dividend Income', isHeader: false,
      },
      {
        key: 'sundry_income_expense_fx_exchange_reverse', value: 'Sundry Income (Expense) Fx Exchange', isHeader: false,
      },
      {
        key: 'sundry_income_expense_reverse', value: 'Sundry Income Expense', isHeader: false,
      },
      {
        key: 'due_to_owners', value: 'Due To Owners', isHeader: false,
      },
      {
        key: 'total_other_tangible_assets', value: 'Total Other Tangeible Aassets', isHeader: false,
      },
      {
        key: 'other_non_current_liabilities', value: 'Other Non Current Liabilities', isHeader: false,
      },
      {
        key: 'gain_loss_on_sale_of_plant_reverse', value: 'Gain Loss On Sale Of Plant', isHeader: false,
      },
      {
        key: 'other_unusual_item_reverse', value: 'Other Unusual Item ', isHeader: false,
      },
      {
        key: 'cash_before_financing', value: 'Cash Before Financing', index: 49, isHeader: false,
      },
      {
        key: 'change_in_short_term_debt', value: 'Change  In Short Term Debt', isHeader: false,
      },
      {
        key: 'change_in_long_term_debt', value: 'Change In Long Term Debt', isHeader: false,
      },
      {
        key: 'change_in_lease', value: 'Change In Lease', isHeader: false,
      },
      {
        key: 'other_grey_area_item', value: 'Other Grey Area Item', isHeader: false,
      },
      {
        key: 'change_in_equity', value: 'Change In  Equity', isHeader: false,
      },
      {
        key: 'cash_before_financing', value: 'Cash Before Financing', index: 49, isHeader: false,
      },
      {
        key: 'net_cash_flow', value: 'Net Cash Flow', isHeader: false,
      },
      {
        key: 'cads', value: 'Cads', isHeader: false,
      },
      {
        key: 'ds', value: 'Ds', isHeader: false,
      },
      {
        key: 'nopat_financing_payments', value: 'Nopat Financing Payments', isHeader: false,
      },
      {
        key: 'copat_financing_payments', value: 'Copat Financing Payments', isHeader: false,
      },
      {
        key: 'cads_ds', value: 'Cads Ds', isHeader: false,
      },

    ],
    factSheetHeader: [
      {
        key: 'depont_formula', value: 'DUPONT FORMULA', isHeader: true,
      },
      {
        key: 'ret_on_sales', value: 'Ret On Sales Npat/Sales', isHeader: false,
      },
      {
        key: 'asset_util', value: 'Asset Util. Sales/Ta', isHeader: false,
      },
      {
        key: 'total_assets_equity_ratio', value: 'Total Assets/Equity', isHeader: false,
      },

      {
        key: 'return_on_equity', value: 'Return On Equity ', isHeader: false,
      },
      {
        key: 'return_on_assets', value: 'Return On Assets', isHeader: false,
      },
      {
        key: 'profitability', value: 'PROFITABILITY', isHeader: true,
      },
      {
        key: 'sales_growth_rate', value: 'Sales Growth Rate', isHeader: false,
      },
      {
        key: 'cogs_sales_ratio', value: 'Cogs/Sales', isHeader: false,
      },
      {
        key: 'gross_profit_sales_ratio', value: 'Gross Profit/Sales', isHeader: false,
      },
      {
        key: 'sg_and_a_sales_ratio', value: 'Sg&A/Sales', isHeader: false,
      },
      {
        key: 'provisions_sales_ratio', value: 'Provision /Sales', isHeader: false,
      },
      {
        key: 'research_and_development_expenses_sales_ratio', value: 'Research & Development Expenses /Sales', isHeader: false,
      },
      {
        key: 'operating_profit_margin', value: 'Operating Profit Margin', isHeader: false,
      },
      {
        key: 'net_profit_before_tax_sales_ratio', value: 'Net Profit Before Tax /Sales', isHeader: false,
      },
      {
        key: 'net_profit_after_tax_sales_ratio', value: 'Net Profit After Tax/Sales', isHeader: false,
      },
      {
        key: 'net_profit_after_unusual_items_sales_ratio', value: 'Net Profit After Unusual Items Npaui /Sales', isHeader: false,
      },
      {
        key: 'asset_efficiency', value: 'ASSET EFFICIENCY', isHeader: true,
      },
      {
        key: 'working_investment', value: 'Working Investment ', isHeader: false,
      },
      {
        key: 'change_in_working_investment', value: '% Change Working Investment', isHeader: false,
      },
      {
        key: 'working_investment_revenue_ratio', value: 'Working Invest./Revenue', isHeader: false,
      },
      {
        key: 'account_receivables_days_on_hand', value: 'Account Receivables Days On Hand', isHeader: false,
      },
      {
        key: 'account_payable_days_on_hand', value: 'Account Payables Days On Hand', isHeader: false,
      },
      {
        key: 'total_inventory_days', value: 'Total Inventory Days', isHeader: false,
      },
      {
        key: 'raw_materials_inv_days', value: 'Raw Material Inv Days', isHeader: false,
      },
      {
        key: 'wip_inv_days', value: 'Wip Inv Days', isHeader: false,
      },
      {
        key: 'fg_inv_days', value: 'Fg Inv Days', isHeader: false,
      },
      {
        key: 'goods_in_transit', value: 'Goods In Transit', isHeader: false,
      },
      {
        key: 'accrued_exp_days', value: 'Accrued Exp Days', isHeader: false,
      },
      {
        key: 'down_payments_doh', value: 'Down Payments Doh', isHeader: false,
      },
      {
        key: 'advance_payments_doh', value: 'Advance Payments Doh', isHeader: false,
      },
      {
        key: 'acc', value: 'ACC', isHeader: false,
      },
      {
        key: 'liquidity_and_leverage', value: 'LIQUIDITY & LEVERAGE', isHeader: true,
      },
      {
        key: 'current_ratio', value: 'Current Ratio', isHeader: false,
      },
      {
        key: 'net_working_capital', value: 'Net Working Capital', isHeader: false,
      },
      {
        key: 'quick_ratio', value: 'Quick Ratio', isHeader: false,
      },
      {
        key: 'coverage_ratio', value: 'Coverage Ratio Ca/Tl', isHeader: false,
      },
      {
        key: 'total_liabilities_net_worth_ratio', value: 'Tot Liabilities/Net Worth', isHeader: false,
      },
      {
        key: 'interest_coverage_ebit', value: 'Interest Coverage:  Ebit', isHeader: false,
      },
      {
        key: 'debt_assets_ratio', value: 'Debt_Assets', isHeader: false,
      },
      // {
      //   value: 'Others Hiden Formulas', isHeader: true,
      // },
      // {
      //   key: 'cash_and_cash_equivalent', value: 'Cash & Cash Equivalent', isHeader: false,
      // },
      // {
      //   key: 'marketable_securities', value: 'Marketable Securities', isHeader: false,
      // },
      // {
      //   key: 'current_due_from_shareholders', value: 'Current Due From Shareholders',
      // isHeader: false,
      // },
      // {
      //   key: 'other_current_assets', value: 'Other Current Asset', isHeader: false,
      // },
      // {
      //   key: 'investments', value: 'Investments', isHeader: false,
      // },
      // {
      //   key: 'due_from_shareholders', value: 'Due From Shareholders', isHeader: false,
      // },
      // {
      //   key: 'other_tangible_asset', value: 'Other Tangible Asset', isHeader: false,
      // },
      // {
      //   key: 'deferred_tax_asset', value: 'Deferred Tax Asset', isHeader: false,
      // },
      // {
      //   key: 'long_term_prepaids', value: 'Long Term Prepaids ', isHeader: false,
      // },
      // {
      //   key: 'goodwill', value: 'Goodwill', isHeader: false,
      // },
      // {
      //   key: 'other_non_current_liabilities', value: 'Other Non-Current Liabilities',
      // isHeader: false,
      // },
      // {
      //   key: 'due_to_owners', value: 'Due To Owners', isHeader: false,
      // },
      // {
      //   key: 'provisions', value: 'Provisions', isHeader: false,
      // },
      // {
      //   key: 'deferred_taxes', value: 'Deferred Taxes', isHeader: false,
      // },
      // {
      //   key: 'minority_interest', value: 'Minority Interest', isHeader: false,
      // },
      // {
      //   key: 'other_grey_area_item', value: 'Other Grey Area Item', isHeader: false,
      // },
      // {
      //   key: 'taxes_payable', value: 'Taxes Payable', isHeader: false,
      // },
      // {
      //   key: 'interests_payable', value: 'Interest Payable', isHeader: false,
      // },
      // {
      //   key: 'dividends_payable', value: 'Dividends Payable', isHeader: false,
      // },
      // {
      //   key: 'due_to_sister_companies', value: 'Due To Sister Companies', isHeader: false,
      // },
      // {
      //   key: 'other_current_liability', value: 'Other Current Liability', isHeader: false,
      // },
      // {
      //   value: 'FINANCIAL STRUCTURE', isHeader: true,
      // },
      // {
      //   key: 'short_term_debt', value: '% Short Term Debt', isHeader: false,
      // },
      // {
      //   key: 'spontaneous_financing', value: '% Spontaneous Financing', isHeader: false,
      // },
      // {
      //   key: 'long_term_debt', value: '% Long Term Debt', isHeader: false,
      // },
      // {
      //   key: 'net_worth', value: '% Net Worth', isHeader: false,
      // },
      // {
      //   key: 'other_liabilities', value: '% Other Liabilities', isHeader: false,
      // },
      // {
      //   key: 'other_grey_area', value: '% Other Grey Area', isHeader: false,
      // },

    ],
    fixedAssetProjectionTitles: [
      { key: 'manufacturing_land', value: 'Manufacturing Land' },
      { key: 'manufacturing_land_depreciation_percentage', value: 'Manufacturing Land Depreciation Percentage', type: true },
      { key: 'manufacturing_land_depreciation', value: 'Manufacturing Land Depreciation', type: 'readonly' },
      { key: 'manufacturing_building_and_improvement', value: 'Manufacturing Building And Improvement' },
      { key: 'manufacturing_building_and_improvement_depreciation_percentage', value: 'Manufacturing Building And Improvement Depreciation Percentage', type: true },
      { key: 'manufacturing_building_and_improvement_depreciation', value: 'Manufacturing Building And Improvement Depreciation', type: 'readonly' },
      { key: 'manufacturing_equipment', value: 'Manufacturing Equipment' },
      { key: 'manufacturing_equipment_depreciation_percentage', value: 'Manufacturing Equipment Depreciation Percentage', type: true },
      { key: 'manufacturing_equipment_depreciation', value: 'Manufacturing Equipment Depreciation', type: 'readonly' },
      { key: 'manufacturing_construction_in_progress', value: 'Manufacturing Construction In Progress' },
      { key: 'manufacturing_construction_in_progress_depreciation_percentage', value: 'Manufacturing Construction In Progress Depreciation Percentage', type: true },
      { key: 'manufacturing_construction_in_progress_depreciation', value: 'Manufacturing Construction In Progress Depreciation', type: 'readonly' },
      { key: 'manufacturing_other', value: 'Manufacturing Other' },
      { key: 'manufacturing_other_depreciation_percentage', value: 'Manufacturing Other Depreciation Percentage', type: true },
      { key: 'manufacturing_other_depreciation', value: 'Manufacturing Other Depreciation', type: 'readonly' },
      { key: 'manufacturing_gross_planet', value: 'Manufacturing Gross Planet', type: 'readonly' },
      { key: 'manufacturing_total_depreciation', value: 'Manufacturing Total Depreciation', type: 'readonly' },
      { key: 'admin_land', value: 'Admin Land' },
      { key: 'admin_land_depreciation_percentage', value: 'Admin Land Depreciation Percentage', type: true },
      { key: 'admin_land_depreciation', value: 'Admin Land Depreciation', type: 'readonly' },
      { key: 'admin_building_and_improvement', value: 'Admin Building And Improvement' },
      { key: 'admin_building_and_improvement_depreciation_percentage', value: 'Admin Building And Improvement Depreciation Percentage', type: true },
      { key: 'admin_building_and_improvement_depreciation', value: 'Admin Building And Improvement Depreciation', type: 'readonly' },
      { key: 'admin_equipment', value: 'Admin Equipment' },
      { key: 'admin_equipment_depreciation_percentage', value: 'Admin Equipment Depreciation Percentage', type: true },
      { key: 'admin_equipment_depreciation', value: 'Admin Equipment Depreciation', type: 'readonly' },
      { key: 'admin_construction_in_progress', value: 'Admin Construction In Progress' },
      { key: 'admin_construction_in_progress_depreciation_percentage', value: 'Admin Construction In Progress Depreciation Percentage', type: true },
      { key: 'admin_construction_in_progress_depreciation', value: 'Admin Construction In Progress Depreciation', type: 'readonly' },
      { key: 'admin_other', value: 'Admin Other' },
      { key: 'admin_other_depreciation_percentage', value: 'Admin Other Depreciation Percentage', type: true },
      { key: 'admin_other_depreciation', value: 'Admin Other Depreciation', type: 'readonly' },
      { key: 'admin_gross_planet', value: 'Admin Gross Planet', type: 'readonly' },
      { key: 'admin_total_depreciation', value: 'Admin Total Depreciation', type: 'readonly' },
      { key: 'land_sold_out', value: 'Land Sold Out' },
      { key: 'building_and_improvement_sold_out', value: 'Building And Improvement Sold Out' },
      { key: 'equipment_sold_out', value: 'Equipment Sold Out' },
      { key: 'construction_in_progress_sold_out', value: 'Construction In Progress Sold Out' },
      { key: 'other_sold_out', value: 'Other Sold Out' },

    ],
    runOffTitles: [
      { key: 'short_new_amount', value: 'Short New Amount' },
      { key: 'current_outstanding_stl', value: 'Current Outstanding STL' },
      { key: 'current_portion_of_current_lease', value: 'Current Portion Of Current Lease' },
      { key: 'current_portion_of_new_lease', value: 'Current Portion Of New Lease', type: 'readonly' },
      { key: 'current_portion_of_current_mtl', value: 'Current Portion Of Current MTL', type: 'readonly' },
      { key: 'current_portion_of_new_mtl', value: 'Current Portion Of New MTL' },
      { key: 'short_total', value: 'Short Total', type: 'readonly' },
      { key: 'short_rate', value: 'Short Rate', type: true },
      { key: 'lease_new_amount', value: 'Lease New Amount', type: 'readonly' },
      { key: 'lease_total_repayments', value: 'Lease Total Repayments', type: 'readonly' },
      { key: 'lease_new_facilities_outstanding', value: 'Lease New Facilities Outstanding', type: 'readonly' },
      { key: 'lease_current_facilities_outstanding', value: 'Lease Current Facilities Outstanding' },
      { key: 'lease_total', value: 'Lease Total', type: 'readonly' },
      { key: 'lease_rate', value: 'Lease Rate', type: 'readonly' },
      { key: 'long_new_amount', value: 'Long New Amount' },
      { key: 'long_total_repayments', value: 'Long Total Repayments' },
      { key: 'long_new_facilities_outstanding', value: 'Long New Facilities Outstanding', type: 'readonly' },
      { key: 'long_current_facilities_outstanding', value: 'Long Current Facilities Outstanding' },
      { key: 'long_total', value: 'Long Total', type: 'readonly' },
      { key: 'long_rate', value: 'Long Rate', type: true },
      { key: 'gross_interest_expense_exist', value: 'Gross Interest Expense Exist', type: 'readonly' },
    ],
    projectionAssumptionTitles: [
      { key: 'net_sales', value: 'Net Sales' },
      { key: 'cost_of_goods_sold', value: 'Cost Of Goods Sold' },
      { key: 'sales_general_admin_expense', value: 'Sales General Admin Expense' },
      { key: 'depreciation', value: 'Depreciation' },
      { key: 'admin_depreciation', value: 'Admin Depreciation' },
      { key: 'provisions', value: 'Provisions' },
      { key: 'research_development_expenses', value: 'Research Development Expenses' },
      { key: 'goodwill_amortization', value: 'Goodwill Amortization' },
      { key: 'other_amortization', value: 'Other Amortization' },
      { key: 'gross_interest_exp_exist', value: 'Gross Interest Exp Exist' },
      { key: 'interest_expense_new', value: 'Interest Expense New' },
      { key: 'interest_income', value: 'Interest Income' },
      { key: 'divided_income', value: 'Divided Income' },
      { key: 'sundry_income_expense_fx_exchange', value: 'Sundry Income Expense Fx Exchange' },
      { key: 'sundry_income_expense', value: 'Sundry Income Expense' },
      { key: 'current_tax_expense', value: 'Current Tax Expense' },
      { key: 'deferred_tax_expense', value: 'Deferred Tax Expense' },
      { key: 'sale_of_plant', value: 'Sale Of Plant' },
      { key: 'other_unusual_item', value: 'Other Unusual Item' },
      { key: 'other_adjustment', value: 'Other Adjustment' },
      { key: 'cash_and_cash_equivalent', value: 'Cash And Cash Equivalent' },
      { key: 'marketable_securities', value: 'Marketable Securities' },
      { key: 'account_receivables', value: 'Account Receivables' },
      { key: 'allowances', value: 'Allowances' },
      { key: 'raw_materials', value: 'Raw Materials' },
      { key: 'work_in_progress', value: 'Work In Progress' },
      { key: 'finished_goods', value: 'Finished Goods' },
      { key: 'goods_in_transit', value: 'Goods In Transit' },
      { key: 'advance_payments_plus_prepaids', value: 'Advance Payments Plus Prepaids' },
      { key: 'other_current_asset', value: 'Other Current Asset' },
      { key: 'investments', value: 'Investments' },
      { key: 'due_from_shareholders', value: 'Due From Shareholders' },
      { key: 'other_tangible_asset', value: 'Other Tangible Asset' },
      { key: 'deferred_tax_asset', value: 'Deferred Tax Asset' },
      { key: 'long_term_prepaids', value: 'Long Term Prepaids' },
      { key: 'goodwill', value: 'Goodwill' },
      { key: 'less_accum_amortization', value: 'Less Accum Amortization' },
      { key: 'accounts_payable', value: 'Accounts Payable' },
      { key: 'taxes_payable', value: 'Taxes Payable' },
      { key: 'accrued_expenses', value: 'Accrued Expenses' },
      { key: 'interest_payable', value: 'Interest Payable' },
      { key: 'dividends_payable', value: 'Dividends Payable' },
      { key: 'down_payments', value: 'Down Payments' },
      { key: 'due_to_sister_companies', value: 'Due To Sister Companies' },
      { key: 'other_current_liability', value: 'Other Current Liability' },
      { key: 'other_non_current_liabilities', value: 'Other Non Current Liabilities' },
      { key: 'due_to_owners', value: 'Due To Owners' },
      { key: 'liability_provisions', value: 'Liability Provisions' },
      { key: 'deferred_taxes', value: 'Deferred Taxes' },
      { key: 'minority_interest', value: 'Minority Interest' },
      { key: 'other_grey_area_item', value: 'Other Grey Area Item' },
      { key: 'preferred_stock', value: 'Preferred Stock' },
      { key: 'common_stock', value: 'Common Stock' },
      { key: 'additional_paid_in_capital', value: 'Additional Paid In Capital' },
      { key: 'dividends', value: 'Dividends' },
      { key: 'ongoing_owners_account', value: 'Ongoing Owners Account' },
      { key: 'legal_reverse', value: 'Legal Reverse' },
      { key: 'fx_adjustment', value: 'Fx Adjustment' },
      { key: 'other_equity_reverses', value: 'Other Equity Reverses' },
    ],
    keyCustomersTitles: [
      { key: 'customer_name', value: 'Name' },
      { key: 'type', value: 'Customer Type' },
      { key: 'sales', value: 'Sales %' },
    ],
    committeesTitles: [
      { key: 'member', value: 'Username' },
      { key: 'member', value: 'Title' },
      { key: 'approved', value: 'Action' },
      { key: 'comment', value: 'Comments' },
    ],
    keyCustomersTermsTitles: [
      { key: 'key_customers_local', value: 'Local' },
      { key: 'key_customers_export', value: 'Export' },
      { key: 'key_customers_sales', value: 'Sales %' },
    ],
    keySuppliersTitles: [
      { key: 'customer_name', value: 'Supplier Name' },
      { key: 'type', value: 'Supplier Type' },
      { key: 'purchases', value: 'Purchases %' },
    ],
    keySuppliersTermsTitles: [
      { key: 'key_suppliers_local', value: 'Local' },
      { key: 'key_suppliers_export', value: 'Export' },
      { key: 'key_suppliers_purchases', value: 'Purchases %' },
    ],
    salesAndProfitabilityTitles: [
      { key: 'net_sales', value: 'Sales' },
      { key: 'sales_growth_rate', value: 'Sales growth rate (%)' },
      { key: 'cogs_sales_ratio', value: 'Cost of Goods Sold (COGS)/Sales (%)' },
      { key: 'gross_profit', value: 'Gross Profit' },
      { key: 'gross_profit_sales_ratio', value: 'Gross Profit Margin %' },
      { key: 'sg_and_a_sales_ratio', value: 'Sales, General and Administrative expenses ' },
      { key: 'net_profit_after_tax', value: 'Net Profit' },
      { key: 'net_profit_after_tax_sales_ratio', value: 'Net Profit Margin %' },
      { key: 'total_equity', value: 'Total Equity' },
      { key: 'return_on_equity', value: 'Return On Equity' },
    ],
    salesAndProfitabilityTitlesRisk: [
      { key: 'net_sales', value: 'Sales' },
      { key: 'cogs_sales_ratio', value: 'Cost of Goods Sold (COGS)/Sales (%)' },
      { key: 'sales_growth_rate', value: 'Sales growth rate (%)' },
      { key: 'gross_profit_sales_ratio', value: 'Gross Profit Margin %' },
      { key: 'net_profit_after_tax_sales_ratio', value: 'Net Profit Margin %' },
      { key: 'net_profit_after_tax', value: 'Net Profit' },
      { key: 'current_ratio', value: 'Current Ratio ' },
      { key: 'total_liabilities_net_worth_ratio', value: 'Leverage' },
      { key: 'return_on_equity', value: 'Return On Equity' },
      { key: 'return_on_assets', value: 'Return on Assets' },
      { key: 'total_inventory_days', value: 'Inventory Days On Hands' },
      { key: 'account_receivables_days_on_hand', value: 'Account Receivables  Days On Hands' },
      { key: 'account_payable_days_on_hand', value: 'Account payable Days on Hands' },
    ],
    assetsEfficiencyTitles: [
      { key: 'working_investment', value: 'Working Investment (EGP000s) ' },
      { key: 'working_investment_revenue_ratio', value: 'Working Investment / SALES %' },
      { key: 'account_receivables_days_on_hand', value: 'Account Receivables  Days On Hands' },
      { key: 'total_inventory_days', value: 'Inventory Days On Hands' },
      { key: 'account_payable_days_on_hand', value: 'Account payable Days on Hands' },
      { key: 'total_assets', value: 'Total Assets' },
      { key: 'return_on_assets', value: 'Return on Assets' },
    ],
    capitalStructureTitles: [
      { key: 'current_ratio', value: 'Current Ratio ' },
      { key: 'net_working_capital', value: 'Net Working Capital' },
      { key: 'total_liabilities_net_worth_ratio', value: 'Leverage' },
      { key: 'short_term_debt', value: '% SHORT TERM DEBT' },
      { key: 'spontaneous_financing', value: '% SPONTANEOUS FINANCING' },
      { key: 'long_term_debt', value: '% LONG TERM DEBT' },
      { key: 'net_worth', value: '% NET WORTH' },
      { key: 'other_liabilities', value: '% OTHER LIABILITIES' },
      { key: 'other_grey_area', value: '% OTHER (GREY AREA)' },
    ],
    cashFlowTitles: [
      { key: 'cash_after_curr_operations', value: 'Cash from operating activities' },
      { key: 'cash_before_financing', value: 'Cash before Financing' },
      { key: 'net_cash_flow', value: 'Net Cash Flow' },
      { key: 'nopat_financing_payments', value: 'Normalized Operating Profit after Tax (NOPAT)' },
      { key: 'copat_financing_payments', value: 'Current Operating Profit after Tax (COPAT) ' },
    ],
    projectionCreditMemoTitels: [
      { key: 'net_sales', value: 'Sales' },
      { key: 'sales_growth_rate', value: 'Sales growth rate (%)' },
      { key: 'cogs_sales_ratio', value: 'Cost of Goods Sold (COGS)/Sales (%)' },
      { key: 'gross_profit_sales_ratio', value: 'Gross Profit Margin %' },
      { key: 'sg_and_a_sales_ratio', value: 'SG&A/SALES' },
      { key: 'net_profit_after_unusual_items', value: 'Net Profit' },
      { key: 'net_profit_after_unusual_items_sales_ratio', value: 'Net Profit Margin %' },
      { key: 'working_investment', value: 'Working Investment (EGP000s) ' },
      { key: 'account_receivables_days_on_hand', value: 'Account Receivables  Days On Hands' },
      { key: 'account_payable_days_on_hand', value: 'ACCOUNT PAYABLES DAYS ON HAND' },
      { key: 'total_inventory_days', value: 'TOTAL INVENTORY DAYS' },
      { key: 'current_ratio', value: 'Current Ratio' },
      { key: 'net_working_capital', value: 'Net Working Capital  ' },
      { key: 'total_liabilities_net_worth_ratio', value: 'Leverage   ' },
      { key: 'cash_after_curr_operations', value: 'Cash from operating activities (in EGP000s) ' },
      { key: 'nopat_financing_payments', value: 'Normalized Operating Profit after Tax (NOPAT) /(Financial payments) ' },
      { key: 'copat_financing_payments', value: 'Current Operating Profit after Tax (COPAT) / /(Financial payments)' },
    ],
    guaranteeForFinance: [],
  },
  mutations: {
    updateGuaranteeType(state, newData) {
      state.guaranteeType = newData;
    },
    updateGuaranteeForFinance(state, newData) {
      state.guaranteeForFinance = newData;
    },
    updateSectorOptions(state, newData) {
      state.sectorOptions = newData;
    },
    updateFacilityPurposes(state, newData) {
      state.facilityPurposes = newData;
    },
    updateFacilityStatusOptions(state, newData) {
      state.facilityStatusOptions = newData;
    },
    updateCreditCommitteeMembers(state, newData) {
      state.creditCommitteeMembers = newData;
    },

    updateExecutiveCommitteeMembers(state, newData) {
      state.executiveCommitteeMembers = newData;
    },

    updateCreditTeamMembers(state, newData) {
      state.creditTeamMembers = newData;
    },

    updateRiskTeamMembers(state, newData) {
      state.riskTeamMembers = newData;
    },
    updateMaritalStatusOptions(state, options) {
      state.maritalStatusOptions = options;
    },
    updateEducationOptions(state, options) {
      state.educationOptions = options;
    },
    updateLegalStructureOptions(state, options) {
      state.legalStructureOptions = options;
    },
    updateIndustryOptions(state, options) {
      state.industryOptions = options;
    },
    updateSubIndustryOptions(state, options) {
      state.subIndustryOptions = options;
    },
    updateHeadquarterOwnershipOptions(state, options) {
      state.headquarterOwnershipOptions = options;
    },
    updateHousingOwnershipOptions(state, options) {
      state.housingOwnershipOptions = options;
    },
    updateManagerRelationOptions(state, options) {
      state.managerRelationOptions = options;
    },
    updateBranchOptions(state, options) {
      state.branchOptions = options;
    },
    updateCurrencyOptions(state, options) {
      state.currencyOptions = options;
    },
    updateAssetTypeOptions(state, options) {
      state.assetTypeOptions = options;
    },
    updateAssetManufactureOptions(state, options) {
      state.assetManufactureOptions = options;
    },
    updateSupplierTypeOptions(state, options) {
      state.supplierTypeOptions = options;
    },
    updateOwnershipDocumentationTypeOptions(state, options) {
      state.ownershipDocumentationTypeOptions = options;
    },
    updateSpeedConversionToCashOptions(state, options) {
      state.speedConversionToCashOptions = options;
    },
    updateAssetValueChangingOptions(state, options) {
      state.assetValueChangingOptions = options;
    },
    updateAssetConditionOptions(state, options) {
      state.assetConditionOptions = options;
    },
    updateGovernorateOptions(state, options) {
      state.governorateOptions = options;
    },
    updateDirectorateOptions(state, options) {
      state.directorateOptions = options;
    },
    updateDistrictOptions(state, options) {
      state.districtOptions = options;
    },
    updateTypeOfRequest(state, options) {
      state.typeOfRequest = options;
    },
    updateBanks(state, options) {
      state.banks = options;
    },
    updateCreditFacilities(state, options) {
      state.creditFacilities = options;
    },

  },
  actions: {
    getGuaranteeType(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/guarantee-type/',
          method: 'get',
        }).then((response) => {
          context.commit('updateGuaranteeType', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateGuaranteeType', []);
          reject(error.response.data);
        });
      });
    },
    getGuaranteeOfFinance(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/guarantee-for-financing/',
          method: 'get',
        }).then((response) => {
          context.commit('updateGuaranteeForFinance', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateGuaranteeForFinance', []);
          reject(error.response.data);
        });
      });
    },
    getCreditCommitteeMembers(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/credit-committee-members/',
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditCommitteeMembers', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateCreditCommitteeMembers', []);
          reject(error.response.data);
        });
      });
    },
    getExecutiveCommitteeMembers(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/executive-committee-members/',
          method: 'get',
        }).then((response) => {
          context.commit('updateExecutiveCommitteeMembers', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateExecutiveCommitteeMembers', []);
          reject(error.response.data);
        });
      });
    },
    getCreditTeamMembers(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/credit-team-members/',
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditTeamMembers', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateCreditTeamMembers', []);
          reject(error.response.data);
        });
      });
    },
    getRiskTeamMembers(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/risk-team-members/',
          method: 'get',
        }).then((response) => {
          context.commit('updateRiskTeamMembers', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateRiskTeamMembers', []);
          reject(error.response.data);
        });
      });
    },
    getSectorOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/customer/sectors/',
          method: 'get',
        }).then((response) => {
          context.commit('updateSectorOptions', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateSectorOptions', []);
          reject(error.response.data);
        });
      });
    },
    getFacilityPurposes(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/type-of-request/',
          method: 'get',
        }).then((response) => {
          context.commit('updateFacilityPurposes', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateFacilityPurposes', []);
          reject(error.response.data);
        });
      });
    },
    getFacilityStatusOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/facility-statuses/',
          method: 'get',
        }).then((response) => {
          context.commit('updateFacilityStatusOptions', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateFacilityStatusOptions', []);
          reject(error.response.data);
        });
      });
    },
    getMaritalStatusOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/marital-status/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateMaritalStatusOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateMaritalStatusOptions', []);
            reject(error.response.data);
          });
      });
    },
    getEducationOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/education/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateEducationOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateEducationOptions', []);
            reject(error.response.data);
          });
      });
    },
    getLegalStructureOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/legal-structure/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateLegalStructureOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateLegalStructureOptions', []);
            reject(error.response.data);
          });
      });
    },
    getIndustryOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/industry/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateIndustryOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateIndustryOptions', []);
            reject(error.response.data);
          });
      });
    },
    getSubIndustryOptions(context, industryId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/options/sub-industry-filter/${industryId}/`,
          method: 'get',
        })
          .then((response) => {
            context.commit('updateSubIndustryOptions', response.data);
            resolve(response.data);
          })

          .catch((error) => {
            context.commit('updateSubIndustryOptions', []);
            reject(error.response.data);
          });
      });
    },
    getHeadquarterOwnershipOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/headquarter-ownership/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateHeadquarterOwnershipOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateHeadquarterOwnershipOptions', []);
            reject(error.response.data);
          });
      });
    },
    getHousingOwnershipOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/housing-ownership/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateHousingOwnershipOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateHousingOwnershipOptions', []);
            reject(error.response.data);
          });
      });
    },
    getManagerRelationOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/manager-relation/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateManagerRelationOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateManagerRelationOptions', []);
            reject(error.response.data);
          });
      });
    },
    getBranchOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/branch/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateBranchOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateBranchOptions', []);
            reject(error.response.data);
          });
      });
    },
    getCurrencyOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/currency/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateCurrencyOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateCurrencyOptions', []);
            reject(error.response.data);
          });
      });
    },
    getAssetTypeOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/asset-type/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateAssetTypeOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateAssetTypeOptions', []);
            reject(error.response.data);
          });
      });
    },
    getAssetManufactureOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/asset-manufacture/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateAssetManufactureOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateAssetManufactureOptions', []);
            reject(error.response.data);
          });
      });
    },
    getSupplierTypeOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/supplier-type/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateSupplierTypeOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateSupplierTypeOptions', []);
            reject(error.response.data);
          });
      });
    },
    getOwnershipDocumentationTypeOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/ownership-documentation-type/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateOwnershipDocumentationTypeOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateOwnershipDocumentationTypeOptions', []);
            reject(error.response.data);
          });
      });
    },
    getSpeedConversionToCashOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/speed-conversion-to-cash/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateSpeedConversionToCashOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateSpeedConversionToCashOptions', []);
            reject(error.response.data);
          });
      });
    },
    getAssetValueChangingOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/asset-value-changing/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateAssetValueChangingOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateAssetValueChangingOptions', []);
            reject(error.response.data);
          });
      });
    },
    getAssetConditionOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/asset-condition/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateAssetConditionOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateAssetConditionOptions', []);
            reject(error.response.data);
          });
      });
    },
    getGovernorateOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/governorate/',
          method: 'get',
        })
          .then((response) => {
            context.commit('updateGovernorateOptions', response.data.results);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateGovernorateOptions', []);
            reject(error.response.data);
          });
      });
    },
    getDirectorateOptions(context, governorateId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/options/directorate-filter/${governorateId}/`,
          method: 'get',
        })
          .then((response) => {
            context.commit('updateDirectorateOptions', response.data);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateDirectorateOptions', []);
            reject(error.response.data);
          });
      });
    },
    getDistrictOptions(context, directorateId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/options/district-filter/${directorateId}/`,
          method: 'get',
        })
          .then((response) => {
            context.commit('updateDistrictOptions', response.data);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit('updateDistrictOptions', []);
            reject(error.response.data);
          });
      });
    },
    getTypeOfRequest(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/type-of-request/',
          method: 'get',
        }).then((response) => {
          context.commit('updateTypeOfRequest', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateTypeOfRequest', []);
          reject(error.response.data);
        });
      });
    },
    getBanks(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/bank-codes/',
          method: 'get',
        }).then((response) => {
          context.commit('updateBanks', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateBanks', []);
          reject(error.response.data);
        });
      });
    },
    getCreditFacilities(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/credit-facility/',
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditFacilities', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateCreditFacilities', []);
          reject(error.response.data);
        });
      });
    },
    // postMaritalStatusOption(context, data) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: '/api/marital-status/',
    //       method: 'post',
    //       data,
    //     })
    //       .then((response) => {
    //         console.log('Created marital status option:', response.data);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         console.log('Error creating marital status option:', error);
    //         reject(error);
    //       });
    //   });
    // },
    // updateMaritalStatusOption(context, { id, data }) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: `/api/marital-status/${id}/`,
    //       method: 'put',
    //       data,
    //     })
    //       .then((response) => {
    //         console.log('Updated marital status option:', response.data);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         console.log('Error updating marital status option:', error);
    //         reject(error);
    //       });
    //   });
    // },
    // deleteMaritalStatusOption(context, id) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: `/api/marital-status/${id}/`,
    //       method: 'delete',
    //     })
    //       .then((response) => {
    //         console.log('Deleted marital status option:', response.data);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         console.log('Error deleting marital status option:', error);
    //         reject(error);
    //       });
    //   });
    // },
  },
  getters: {
    committeesTitles(state) {
      return state.committeesTitles;
    },
    getCreditCommitteeMembers(state) {
      return state.creditCommitteeMembers;
    },
    getExecutiveCommitteeMembers(state) {
      return state.executiveCommitteeMembers;
    },
    getCreditTeamMembers(state) {
      return state.creditTeamMembers;
    },
    getRiskTeamMembers(state) {
      return state.riskTeamMembers;
    },
    managerRelation(state) {
      return state.managerRelationOptions;
    },
    sex(state) {
      return state.sex;
    },
    idType(state) {
      return state.idType;
    },
    industry(state) {
      return state.industryOptions;
    },
    subIndustry(state) {
      return state.subIndustryOptions;
    },
    legalStructure(state) {
      return state.legalStructureOptions;
    },
    loansType(state) {
      return state.loansType;
    },
    loanPurpose(state) {
      return state.loanPurpose;
    },
    currencies(state) {
      return state.currencies;
    },
    interestRateType(state) {
      return state.interestRateType;
    },
    repaymentFrequencyType(state) {
      return state.repaymentFrequencyType;
    },
    housingOwnership(state) {
      return state.housingOwnershipOptions;
    },
    headquarterOwnership(state) {
      return state.headquarterOwnershipOptions;
    },
    getEducationOptions(state) {
      return state.educationOptions;
    },
    maritalStatus(state) {
      return state.maritalStatusOptions;
    },
    typeOfInsurance(state) {
      return state.typeOfInsurance;
    },
    countryList(state) {
      const arrayOfObjects = Object.keys(state.countryList).map((key) => ({
        key,
        value: state.countryList[key],
      }));
      return arrayOfObjects;
    },
    yearMonths(state) {
      const arrayOfObjects = Object.keys(state.yearMonths).map((key) => ({
        key,
        value: state.yearMonths[key],
      }));
      return arrayOfObjects;
    },
    tearmsInMonths(state) {
      const arrayOfObjects = Object.keys(state.tearmsInMonths).map((key) => ({
        key: parseInt(key, 10),
        value: state.tearmsInMonths[key],
      }));
      return arrayOfObjects;
    },
    leaseRentType(state) {
      const arrayOfObjects = Object.keys(state.leaseRentType).map((key) => ({
        key: parseInt(key, 10),
        value: state.leaseRentType[key],
      }));
      return arrayOfObjects;
    },
    sectorOptions(state) {
      return state.sectorOptions;
    },
    facilityPurposes(state) {
      return state.facilityPurposes;
    },
    branchOptions(state) {
      return state.branchOptions;
    },
    currencyOptions(state) {
      return state.currencyOptions;
    },
    assetTypeOptions(state) {
      return state.assetTypeOptions;
    },
    assetManufactureOptions(state) {
      return state.assetManufactureOptions;
    },
    supplierTypeOptions(state) {
      return state.supplierTypeOptions;
    },
    ownershipDocumentationTypeOptions(state) {
      return state.ownershipDocumentationTypeOptions;
    },
    speedConversionToCashOptions(state) {
      return state.speedConversionToCashOptions;
    },
    assetValueChangingOptions(state) {
      return state.assetValueChangingOptions;
    },
    assetConditionOptions(state) {
      return state.assetConditionOptions;
    },
    governorateOptions(state) {
      return state.governorateOptions;
    },
    directorateOptions(state) {
      return state.directorateOptions;
    },
    districtOptions(state) {
      return state.districtOptions;
    },
    typeOfFacility(state) {
      return state.typeOfFacility;
    },
    facilityStatusOptions(state) {
      return state.facilityStatusOptions;
    },
    typeOfInstallment(state) {
      return state.typeOfInstallment;
    },
    typeOfRequest(state) {
      return state.typeOfRequest;
    },
    financingTerm(state) {
      return state.financingTerm;
    },
    banks(state) {
      return state.banks;
    },
    guaranteeType(state) {
      return state.guaranteeType;
    },
    guaranteeForFinance(state) {
      return state.guaranteeForFinance;
    },
    creditFacilities(state) {
      return state.creditFacilities;
    },
    incomeStatementHeader(state) {
      return state.incomeStatementHeader;
    },
    liabEquityHeader(state) {
      return state.liabEquityHeader;
    },
    assetsHeader(state) {
      return state.assetsHeader;
    },
    cashFlowHeader(state) {
      return state.cashFlowHeader;
    },
    factSheetHeader(state) {
      return state.factSheetHeader;
    },
    fixedAssetProjectionTitles(state) {
      return state.fixedAssetProjectionTitles;
    },
    runOffTitles(state) {
      return state.runOffTitles;
    },
    projectionAssumptionTitles(state) {
      return state.projectionAssumptionTitles;
    },
    salesAndProfitabilityTitles(state) {
      return state.salesAndProfitabilityTitles;
    },
    salesAndProfitabilityTitlesRisk(state) {
      return state.salesAndProfitabilityTitlesRisk;
    },
    assetsEfficiencyTitles(state) {
      return state.assetsEfficiencyTitles;
    },
    capitalStructureTitles(state) {
      return state.capitalStructureTitles;
    },
    cashFlowTitles(state) {
      return state.cashFlowTitles;
    },
    projectionCreditMemoTitels(state) {
      return state.projectionCreditMemoTitels;
    },
    keyCustomersTitles(state) {
      return state.keyCustomersTitles;
    },
    keyCustomersTermTitles(state) {
      return state.keyCustomersTitles;
    },
    keySuppliersTitles(state) {
      return state.keyCustomersTitles;
    },
    keySuppliersTermsTitles(state) {
      return state.keyCustomersTitles;
    },
    customerType(state) {
      return state.customerType;
    },
  },
};
