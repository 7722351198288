import axios from 'axios';

export default {
  namespaced: true,
  state: {
    guarantees: [],
    personalGuarantees: [],
    arguments: [],
    argument: {
      id: null,
      facility_amount: null,
      pd: null,
    },
    summarizedOptions: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  mutations: {
    updateGuarantees(state, newData) {
      const array = [];
      state.guarantees = newData;
      newData.forEach((element) => {
        element.personal_guarantors.forEach((personal) => {
          array.push(personal);
        });
      });
      state.personalGuarantees = array;
    },
    updatePersonalGuarantees(state, newData) {
      state.personalGuarantees = newData;
    },
    updatesummarizedOptions(state, newData) {
      state.summarizedOptions = newData;
    },
    updateArgument(state, newData) {
      state.argument = newData;
    },
    updateArguments(state, newData) {
      state.arguments = newData;
    },
  },
  actions: {
    getGuarantees(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/guarantees/?investigation=${investigationId}`,
          method: 'get',
        }).then((response) => {
          context.commit('updateGuarantees', response.data.results);
          resolve(response.data.results);
        }).catch((error) => {
          context.commit('updateGuarantees', []);
          reject(error.response.data);
        });
      });
    },
    deletePersonalGuarantees(context, personalGuaranteeId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/personal-guarantors/${personalGuaranteeId}/`,
          method: 'delete',
        }).then((response) => {
          console.log('Deleted Personal Guarantee: ', response.data);
        }).catch((error) => {
          console.log('Error deleting Personal Guarantee: ', error);
          reject(error.response.data);
        });
      });
    },
    getPersonalGuarantees(context, investigationId) {
      console.log('investigationId', investigationId);
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/personal-guarantors/',
          method: 'get',
        }).then((response) => {
          console.log('response.data.result personal', response.data.results);
          context.commit('updatePersonalGuarantees', response.data.results);
          resolve(response.data.results);
        }).catch((error) => {
          context.commit('updatePersonalGuarantees', []);
          reject(error.response.data);
        });
      });
    },
    saveGuarantees(context, guaranteesInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/guarantees/',
          method: 'post',
          data: guaranteesInfo,
        })
          .then((response) => {
            context.commit('updateGuarantees', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    savePersonalGuaranteess(context, guaranteesInfo) {
      console.log(guaranteesInfo);
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/credit/income-statement-periods/',
          method: 'post',
          data: guaranteesInfo,
        })
          .then((response) => {
            context.commit('updatePersonalGuarantees', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSummarizedOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/risk/orr-sections/',
          method: 'get',
        }).then((response) => {
          context.commit('updatesummarizedOptions', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updatesummarizedOptions', []);
          reject(error.response.data);
        });
      });
    },
    getArguments(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/guarantees-arguments/',
          method: 'get',
        }).then((response) => {
          context.commit('updateArguments', response.data.results);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateArguments', []);
          reject(error.response.data);
        });
      });
    },
    getArgumentByInvestigation(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/guarantees-arguments/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateArgument', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateArgument', {});
          reject(error.response.data);
        });
      });
    },
    saveArguments(context, argumentsInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/guarantees-arguments/',
          method: 'post',
          data: argumentsInfo,
        })
          .then((response) => {
            context.commit('updateArguments', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    putArguments(context, argumentsInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/guarantees-arguments/${argumentsInfo.id}/`,
          method: 'put',
          data: argumentsInfo,
        })
          .then((response) => {
            context.commit('updateArguments', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    getGuarantees(state) {
      return state.guarantees;
    },
    getPersonalGuarantees(state) {
      return state.personalGuarantees;
    },
    arguments(state) {
      return state.arguments;
    },
    argument(state) {
      return state.argument;
    },
    summarizedOptions(state) {
      return state.summarizedOptions;
    },
  },
};
