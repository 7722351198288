import axios from 'axios';

export default {
  namespaced: true,
  state: {
    currentFacilities: [],
    previousFacilities: [],
    bankingInquire: [],
    summarizedOptions: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  mutations: {
    updateCurrentFacilities(state, newData) {
      state.currentFacilities = newData;
    },
    updatePreviousFacilities(state, newData) {
      state.previousFacilities = newData;
    },
    updateBankingInquire(state, newData) {
      state.bankingInquire = newData;
    },
    updatesummarizedOptions(state, newData) {
      state.summarizedOptions = newData;
    },
  },
  actions: {
    getCurrentFacilities(context, customerId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/current-facilities/?customer=${customerId}`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCurrentFacilities', response.data.results);
          resolve(response.data.results);
        }).catch((error) => {
          context.commit('updateCurrentFacilities', []);
          reject(error.response.data);
        });
      });
    },
    getPreviousFacilities(context, customerId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/previous-facilities/?customer=${customerId}`,
          method: 'get',
        }).then((response) => {
          context.commit('updatePreviousFacilities', response.data.results);
          resolve(response.data.results);
        }).catch((error) => {
          context.commit('updatePreviousFacilities', []);
          reject(error.response.data);
        });
      });
    },
    getBankingInquire(context, customerId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/banking-inquiries/?customer=${customerId}`,
          method: 'get',
        }).then((response) => {
          context.commit('updateBankingInquire', response.data.results);
          resolve(response.data.results);
        }).catch((error) => {
          context.commit('updateBankingInquire', []);
          reject(error.response.data);
        });
      });
    },
    saveCurrentFacilities(context, facilitiesInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/current-facilities/',
          method: 'post',
          data: facilitiesInfo,
        })
          .then((response) => {
            context.commit('updateCurrentFacilities', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    savePreviousFacilitiess(context, facilitiesInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/previous-facilities/',
          method: 'post',
          data: facilitiesInfo,
        })
          .then((response) => {
            context.commit('updatePreviousFacilities', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveBankingInquire(context, facilitiesInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/facilities-guarantees/banking-inquiries/',
          method: 'post',
          data: facilitiesInfo,
        })
          .then((response) => {
            context.commit('updateBankingInquire', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteCurrentFacilities(context, facilityId) {
      console.log('facilityId', facilityId);
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/current-facilities/${facilityId}/`,
          method: 'DELETE',
        }).then((response) => {
          console.log('Deleted Personal Guarantee: ', response.data);
        }).catch((error) => {
          console.log('Error deleting Personal Guarantee: ', error);
          reject(error);
          // reject(error.response.data);
        });
      });
    },
    deletePreviousFacilities(context, facilityId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/previous-facilities/${facilityId}/`,
          method: 'delete',
        }).then((response) => {
          console.log('Deleted Personal Guarantee: ', response.data);
        }).catch((error) => {
          console.log('Error deleting Personal Guarantee: ', error);
          reject(error);
          // reject(error.response.data);
        });
      });
    },
    deleteBankingFacilities(context, facilityId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/facilities-guarantees/banking-inquiries/${facilityId}/`,
          method: 'delete',
        }).then((response) => {
          console.log('Deleted Personal Guarantee: ', response.data);
        }).catch((error) => {
          console.log('Error deleting Personal Guarantee: ', error);
          reject(error);
          // reject(error.response.data);
        });
      });
    },
    getSummarizedOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/risk/orr-sections/',
          method: 'get',
        }).then((response) => {
          context.commit('updatesummarizedOptions', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updatesummarizedOptions', []);
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    currentFacilities(state) {
      return state.currentFacilities;
    },
    previousFacilities(state) {
      return state.previousFacilities;
    },
    bankingInquire(state) {
      return state.bankingInquire;
    },
    summarizedOptions(state) {
      return state.summarizedOptions;
    },
  },
};
