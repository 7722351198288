const customerFormPage = {
  generalInformation: 'General Information',
  requestDate: 'Request date',
  customerNumber: 'Customer Number',
  branch: 'Branch',
  companyName: 'Company Name',
  tradeName: 'Trade Name',
  contactPerson: 'Contact person',
  contactPersonNumber: 'Contact person number',
  contactPersonEmail: 'Contact person email',
  responsibleRm: 'Responsible RM',
  businessStartDate: 'Business Start Date',
  numberOfEmployees: 'Number Of Employees',
  numberOfBranches: 'Number Of Branches',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  realActivity: 'Real Activity',
  otherActivities: 'Other activities',
  activityLicenseNumber: 'Activity License Number',
  lastRenewalDate: 'Last Renewal Date',
  licenseExpireDate: 'License Expire Date',
  companyLegalStructure: 'Company Legal Structure',
  activityStreet: 'Activity Street',
  governorate: 'Governorate',
  directorate: 'Directorate',
  district: 'District',
  neighbor: 'Neighbor',
  activityHousingOwnership: 'Activity Housing Ownership',
  commercialRegisterNumber: 'Commercial Register Number',
  telephone: 'Telephone',
  activityMobileNumber: 'Activity Mobile Number',
  activityEmailAddress: 'Activity Email Address',
  responsibleForManagement: 'Responsible for management',
  managerRelation: 'Manager Relation',
  managerMobileNumber: 'Manager mobile number',
  managerEmail: 'Manager Email',
  companyInfoFile: 'Company info file',
  uploadFiles: 'Upload Files',
  addPartner: 'Add Partner',
  saveAsDraft: 'Save As Draft',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  areYouSureDelete: 'Are you sure you want to delete this partner?',
  areYouSureSave: 'Are you sure you want to save?',
  areYouSureDeleteFiles: 'Are you sure you want to delete those files?',
  showFiles: 'Show Files',
  delete: 'Delete',
  ownerName: 'Owner Name',
  percentage: 'Percentage',
  partnerBirthDate: 'Partner Birth date',
  partnerAge: 'Partner Age',
  educationLevel: 'Education Level',
  yearsOfExperience: 'Years of Experience',
  summary: 'Summary',
  generalInfo: 'General Information',
  companyInformation: 'Company Information',
  commercialRegisterFile: 'Commercial Register File',
  activityData: 'Activity Data',
  ownershipInformation: 'Ownership Information',
  showComercialRegister: 'Show Commercial Register files',
  idInformation: 'ID Information',
  clientName: 'Client Name',
  customerIdType: 'Customer ID Type',
  customerIdNumber: 'Customer ID Number',
  customerIdFile: 'Customer ID File',
  showCustomerIdFile: 'Show Customer ID File',
  idIssueDate: 'ID Issue Date',
  idExpireDate: 'ID Expire Date',
  idIssuePlace: 'ID Issue Place',
  idType: 'ID Type',
  idNumber: 'ID Number',
  releaseDate: 'Release Date',
  birthDate: 'Birth Date',
  birthCountray: 'Country Of Birth',
  gender: 'Gender',
  nationality: 'Nationality',
  idFile: 'ID File',
  maritalStatus: 'Marital Status',
  noOfSons: 'Number of sons',
  personalInfo: 'Personal Information',
  street: 'Street',
  housingOwnership: 'Housing Ownership',
  mobileNumber: 'Mobile Number',
  email: 'Email',
  employerName: 'Employer Name',
  employerStatus: 'Employer Statue',
  jobTitle: 'Job Title',
  lengthOfEmployement: 'Length of Employement',
  monthlyIncome: 'Monthly Income',
  otherIncomeSources: 'Other Income Sources',
  uploadCustomerHousingOwnership: 'Upload Customer Housing Ownership',
  showCustomerHousingOwnership: 'Show Customer Housing Ownership Files',
  atLeastOnePartner: 'At Least One Partner Should Be Entered!',
  ownerPercentage: 'Owner Percentage',
  no: 'No',
  yes: 'Yes',
  ok: 'OK',
  requestedLoanInfo: 'Requested Loan Information',
  typeOfFacility: 'Type of facility',
  currency: 'Currency',
  amount: 'Amount',
  facilityDuration: 'Loan Duration',
  purposeOfLoan: 'Purpose of Loan',
  interestRateType: 'Interest Rate Type',
  interestRate: 'Interest Rate',
  repaymentFrequencyType: 'Repayment Frequency Type',
  repaymentFrequencyValue: 'Repayment Frequency Amount',
  repaymentFrequencyTotal: 'Total Repayment Amount',
  repaymentFrequencyDueDate: 'Payment Due Dates',
  passport: 'Passport',
  nationalID: 'National ID',
  workingCapitalLoan: 'Working Capital Loan',
  assetFinanceLoan: 'Asset Finance Loan',
  others: 'Others',
};

const requestFormPage = {
  totalRequests: 'Total Requests',
  customerRequestInfo: 'Customer Request Info',
  requestDate: 'Request date',
  serialNumber: 'Serial number',
  customerNumber: 'Customer Number',
  branch: 'Branch',
  companyName: 'Company Name',
  tradeName: 'Trade Name',
  typeOfRequest: 'Type of request',
  typeOfFacility: 'Type of facility',
  facilityDuration: 'Facility Duration',
  typeOfInstallment: 'Type of installment',
  assetsInformation: 'Assets Information',
  addAsset: 'Add Asset',
  assetToBeFinanced: 'Asset to be financed',
  assetType: 'Asset type',
  assetDescription: 'Asset description',
  assetCost: 'Asset Cost',
  downPaymentSecurityMargin: 'Down payment (security margin%)',
  downPayment: 'Down payment',
  currency: 'Currency',
  purposeOfFinance: 'Purpose of finance',
  assetCondition: 'Asset condition',
  dateOfOrigin: 'Date of origin',
  organizationCountry: 'Organization country',
  supplierType: 'Supplier type',
  depositorName: 'Depositor name',
  assetManufacture: 'Asset manufacture',
  ownershipDocumentationType: 'Ownership documentation type',
  speedConversionToCash: 'Speed conversion to cash',
  assetValueChanging: 'Asset value changing',
  evaluationReportDate: 'Evaluation Report Date',
  evaluationReportRenewalDate: 'Evaluation Report Renewal Date',
  supplierInformation: 'Supplier Information',
  supplierName: 'Supplier Name',
  assetDepreciation: 'Asset Depreciation',
  insuranceDate: 'Insurance Date',
  insuranceAmount: 'Insurance Amount',
  typeOfInsurance: 'Type Of Insurance',
  insurancePolicy: 'Insurance Policy',
  saveAsDraft: 'Save As Draft',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  areYouSureDeleteAsset: 'Are you sure you want to delete this asset?',
  areYouSureSave: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnnual: 'Semi Annual',
  annually: 'Annually',
  fixed: 'Fixed',
  variable: 'Variable',
  personalLoan: 'Personal Loan',
  homeLoan: 'Home Loan',
  autoLoan: 'Auto Loan',
  studentLoan: 'Student Loan',
  businessLoan: 'Business Loan',
  paydayLoan: 'Payday Loan',
  mortgageLoan: 'Mortgage Loan',
  egyptianPound: 'Egyptian Pound',
  unitedStatesDollar: 'United States Dollar',
  euro: 'Euro',
  britishPoundSterling: 'British Pound Sterling',
  japaneseYen: 'Japanese Yen',
  australianDollar: 'Australian Dollar',
  canadianDollar: 'Canadian Dollar',
  swissFranc: 'Swiss Franc',
  chineseYuan: 'Chinese Yuan',
  indianRupee: 'Indian Rupee',
  brazilianReal: 'Brazilian Real',
};
const requestForm = {
  customerRequestInfo: 'Customer Request Info',
  requestDate: 'Request date',
  WillBeGenerated: 'Will be generated',
  serialNumber: 'Serial number',
  customerNumber: 'Customer Number',
  branch: 'Branch',
  companyName: 'Company Name',
  tradeName: 'Trade Name',
  typeOfRequest: 'Type of request',
  typeOfFacility: 'Type of facility',
  facilityDuration: 'Facility Duration',
  typeOfInstallment: 'Type of installment',
  assetsInformation: 'Assets Information',
  addAsset: 'Add Asset',
  atLeastOnePartner: 'At least one partner should be entered!',
  assetToBeFinanced: 'Asset to be financed',
  assetType: 'Asset type',
  assetName: 'Asset Name',
  assetDescription: 'Asset description',
  assetCost: 'Asset Cost',
  amountValue: 'Amount value',
  downPaymentSecurity: 'Down payment (security margin%)',
  downPayment: 'Down payment (security margin%)',
  currency: 'Currency',
  loanAmount: 'Loan Amount',
  requestNumber: 'Request Number',
  customerName: 'Customer Name',
  requestStatus: 'Request Status',
  loanType: 'Loan Type',
  select: 'Select',
  selectYear: 'Select Year',
  purposeOfFinance: 'Purpose of finance',
  assetCondition: 'Asset condition',
  dateOfOrigin: 'Date of origin',
  organizationCountry: 'Organization country',
  supplierType: 'Supplier type',
  depositorName: 'Depositor name',
  assetManufacture: 'Asset manufacture',
  ownershipDocumentationType: 'Ownership documentation type',
  speedConversionToCash: 'Speed conversion to cash',
  assetValueChanging: 'Asset value changing',
  evaluationReportDate: 'Evaluation Report Date',
  evaluationReportRenewalDate: 'Evaluation Report Renewal Date',
  supplierInformation: 'Supplier Information',
  supplierName: 'Supplier Name',
  assetDepreciation: 'Asset Depreciation',
  insuranceDate: 'Insurance Date',
  insuranceAmount: 'Insurance Amount',
  typeOfInsurance: 'Type Of Insurance',
  insurancePolicy: 'Insurance Policy',
  saveAsDraft: 'Save As Draft',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  areYouSureDeleteAsset: 'Are you sure you want to delete this asset?',
  areYouSureSave: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  // dropdowns
  directLeasing: 'Direct leasing',
  saleWithLeaseback: 'Sale with leaseback',
  amend: 'Amend',
  decrease: 'Decrease',
  increase: 'Increase',
  new: 'New',
  renewal: 'Renewal',
  reschedule: 'Reschedule',
  writeOff: 'Write off',
  oneYear: 'One year',
  twoYears: 'Two years',
  threeYears: 'Three years',
  fourYears: 'Four years',
  fiveYears: 'Five years',
  sixYears: 'Six years',
  sevenYears: 'Seven years',
  eightYears: 'Eight years',
  nineYears: 'Nine years',
  tenYears: 'Ten years',
  monthlyInAdvanced: 'Monthly in advanced',
  monthlyInArrears: 'Monthly in arrears',
  quarterlyInAdvanced: 'Quarterly in advanced',
  quarterlyInArrears: 'Quarterly in arrears',
  semiAnnualInAdvanced: 'Semi-annual in advanced',
  semiAnnualInArrears: 'Semi-annual in arrears',
};
const investigationFormPage = {
  summery: 'Summery',
  scrollToTop: 'Scroll To Top',
  investigationForm: 'Investigation Form',
  // Customer Information Tab
  customerInfo: 'Customer Information',
  totalInvestigation: 'Total Investigation',
  customerRequestInfo: 'Customer Request Info',
  customerNumber: 'Customer Number',
  requestDate: 'Request Date',
  visitDate: 'Visit Date',
  branch: 'Branch',
  generalInformation: 'General Information',
  activityData: 'Activity Data',
  companyName: 'Company Name',
  tradeName: 'Trade Name',
  companyLegalStructure: 'Company Legal Structure',
  amountOfCapital: 'Amount of Capital',
  lastCapitalIncreaseDate: 'Last Capital Increase Date',
  authorizedCapital: 'Authorized Capital',
  capitalPaid: 'Capital Paid',
  issuedCapital: 'Issued Capital',
  businessStartDate: 'Business Start Date',
  numberOfBranches: 'Number of Branches',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  commercialRegisterNumber: 'Commercial Register Number',
  realActivity: 'Real Activity',
  lastRenewalDate: 'Last Renewal Date',
  licenseRenewalDate: 'License Renewal Date',
  licenseNumber: 'License Number',
  otherActivities: 'Other Activities',
  companyInformation: 'Company Information',
  governorate: 'Governorate',
  directorate: 'Directorate',
  district: 'District',
  street: 'Street',
  neighbor: 'Neighbor',
  headquarterOwnership: 'Headquarter Ownership',
  telephone: 'Telephone',
  mobileNumber: 'Mobile Number',
  emailAddress: 'Email Address',
  responsibleRm: 'Responsible RM',
  contactPerson: 'Contact Person',
  contactPersonEmailAddress: 'Contact Person Email Address',
  contactPersonNumber: 'Contact Person Number',
  responsibleForManagement: 'Responsible for Management',
  managerRelation: 'Manager Relation',
  managerMobileNumber: 'Manager Mobile Number',
  summary: 'Summary',
  ownershipInformation: 'Ownership Information',
  ownerName: 'Owner Name',
  percentage: 'Percentage',
  ownerPercentage: 'Owner Percentage',
  partnerBirthDate: 'Partner Birth Date',
  partnerAge: 'Partner Age',
  amount: 'Amount',
  ownerAmount: 'Owner Amount',
  educationLevel: 'Education Level',
  yearsOfExperience: 'Years of Experience',
  addPartner: 'Add Partner',
  atLeastOnePartner: 'At Least One Partner Should Be Entered!',
  saveAsDraft: 'Save as draft',
  confirmDelete: 'Are you sure you want to delete this partner?',
  confirmSave: 'Are you sure you want to save?',
  // Management & Employees Tab
  managementAndEmployees: 'Management',
  management: 'Management',
  yearsOfExperienceInFieldForOwners: 'How many years of experience in the same field for owners?',
  comment: 'Comment',
  currentNumberOfEmployees: 'What is the current number of employees?',
  permanentEmployeeNumber: 'Permanent Employee Number',
  seasonalEmployeeNumber: 'Seasonal Employee Number',
  rightNumberOfWorkload: 'What is the right number of workload?',
  staffTrained: 'Are the staff trained?',
  yes: 'Yes',
  no: 'No',
  technicalManagementExpertise: 'Is there Technical and Management expertise in the Management team?',
  companyStructure: 'Is there a Company structure?',
  futurePlans: 'Does the company have any plans for upcoming years?',
  keyPerson: 'Who is the key person of the project?',
  assetPosition: 'Asset position',
  equipmentCount: 'How many equipment used in production?',
  productionYearsOfEquipment: 'Production years of equipment?',
  equipmentMaintained: 'Is the equipment maintained?',
  equipmentLastDateOfMaintenance: 'Equipment Last Date of Maintenance',
  enoughEquipment: 'Is there enough equipment for all employees to use?',
  equipmentUsedMoreThanNormal: 'Is this equipment being used more than normal?',
  currentProductionSize: 'The average size of the current production of machines?',
  generalProductionSize: 'The general average of machinery production for similar type?',
  projectLocationAndHeadquarter: 'Project Location and Headquarter',
  projectLocationCloseToCity: 'Is the project location close to the city?',
  projectLocationAccessible: 'Is it easily accessible?',
  projectLocationVisible: 'Can the project be easily seen on the way?',
  similarProjectsInLocation: 'Are there similar projects in the same location?',
  activeMarketForProjectActivity: 'Is the location considered an active market for project activity?',
  occupationalSafetyProblems: 'Are there any occupational safety problems?',
  headquartersSizeAppropriate: 'Is the size of the headquarter appropriate, or too small or too large for the required?',
  branchesExist: 'Is there any branches?',
  branchCount: 'How Many Branches?',
  headquartersMaintained: 'Is the headquarters ownership or rent and is it adequately maintained?',
  products: 'Products',
  enoughRawMaterials: 'Are there a lot of raw materials?',
  workProgress: 'Is work in progress',
  goodsFinishAndSizeAppropriate: 'Are there finished goods? (size?)',
  productCondition: 'Is the product in good condition or supply conditions suitable for its nature?',
  qualityControlReview: 'Is there adequate control over operation and stock in terms of safety, inspection and quality?',
  managementEmployeesSummary: 'Management & employee summary',
  // Main Clients Tab
  mainClients: 'Main Clients',
  clientInfo: 'Client Info',
  addClient: 'Add Client',
  contactPersonName: 'Contact Person Name',
  contactNumber: 'Contact Number',
  address: 'Address',
  wayOfProcess: 'Way of Process',
  cash: 'Cash',
  defer: 'Defer',
  feedback: 'Feedback',
  // Main Suppliers Tab
  mainSuppliers: 'Main Suppliers',
  supplierInfo: 'Supplier info',
  addSupplier: 'Add Supplier',
  // Tax and Insurance Status Tab
  taxAndInsuranceStatus: 'Tax & Insurance Status',
  insuranceStatus: 'Insurance Status',
  taxStatus: 'Tax Status',
  taxInsurenceSummery: 'Tax & insurance status summary',
  // CBE Position Tab
  cbePosition: 'CBE Position',
  cbeCode: 'CBE Code',
  cbeIndustryCode: 'CBE Industry Code',
  numberOfBanks: 'Number of Banks',
  dateOfAcp: 'Date of ACP',
  facilityInfo: 'Facility Info',
  addFacilityInfo: 'Add Facility Info',
  facility: 'Facility',
  creditFacility: 'Credit Facility',
  selectCreditFacility: 'Select',
  authorizedFacilities: 'Authorized Facilities',
  usedFacilities: 'Used Facilities',
  total: 'Total',
  totalAuthorizedFacilities: 'Total Authorized Facilities',
  totalUsedFacilities: 'Total Used Facilities',
  bankInfo: 'Bank Info',
  addBank: 'Add Bank',
  bankCode: 'Bank Code',
  bankName: 'Bank Name',
  firstDealDate: 'First Deal Date',
  iscoreRating: 'iScore Rating',
  situationOfNegativeList: 'Situation Of Negative List',
  iscoreSummary: 'iScore Summary',
  cbePositionSummery: 'CBE position summary',
  // Mortgages Tab
  mortgages: 'Mortgages',
  commercialRegister: 'Commercial Register',
  commercialRegisterComment: 'Commercial Register Comment',
  cbe: 'CBE',
  cbeComment: 'CBE Comment',
  morgtagesSummary: 'Morgtages summary',
  ecrInformation: 'ECR Information',
  ecrNumber: 'ECR Number',
  amountOfAssetsRegistered: 'Amount of Assets Registered',
  dateOfRegistration: 'Date of Registration',
  descriptionOfAssetsRegistered: 'Description of assets Registered',
  ecrAmount: 'ECR Amount',
  // Guarantess Tab
  guarantess: 'Guarantess',
  guarantorCompanyProvidingGuarantees: 'Guarantor (company providing guarantees)',
  addGuarantor: 'Add Guarantor',
  guarantorName: 'Guarantor Name',
  guarantorBank: 'Guarantor Bank',
  guaranteeCompanyReceivingGuarantees: 'Guarantee (company receiving guarantees)',
  addGuarantee: 'Add Guarantee',
  guaranteeName: 'Guarantee Name',
  guaranteeBank: 'Guarantee Bank',
  guarantorGuaranteeSummary: 'Guarantor & guarantee summary',
  // iScore Tab
  iscore: 'iScore',
  addNewIscore: 'Add New iScore',
  addIscore: 'Add iScore',
  facilityAmount: 'Facility Amount',
  currency: 'Currency',
  interestRate: 'Interest Rate',
  facilityType: 'Facility Type',
  facilityDuration: 'Facility Duration',
  facilityPurpose: 'Facility Purpose',
  facilityDate: 'Facility Date',
  amountInArrears: 'Amount in Arrears',
  recurrencesOfDelays: 'Recurrences of delays during the life of the facility',
  arrears: 'Arrears',
  status: 'Status',
  settlementDate: 'Settlement Date',
  warranty: 'Warranty',
  typeOfGuarantee: 'Type of guarantee',
  totalFacilityAmount: 'Total Facility Amount',
  name: 'Name',
  // Protesto and Bankruptcy Tab
  protestoAndBankruptcy: 'Protesto & Bankruptcy',
  protestoComment: 'Protesto Comment',
  bankruptcyComment: 'Bankruptcy Comment',
  protestoBankruptcySummary: 'Protesto & bankruptcy summary',
  investigations: 'Investigations',
  noInvestigationReportFound: 'No Investigation report found.',
  loadingInvestigationData: 'Loading investigation data. Please wait.',
  serialNumber: 'Serial Number',
  actionHeader: 'Action',
  viewInvestigation: 'View Investigation',
  startCreditReport: 'Start Credit Report',
  editInvestigation: 'Edit Investigation',
  confirm: 'Confirm',
  submit: 'Submit',
  cancel: 'Cancel',
};
const cashflowPage = {
  cashflow: 'Cashflow',
};
const facilitiesPage = {
  currentFacility: 'Current Facility',
  addCurrentFacility: 'Add Current Facility',
  facilityAmount: 'Facility amount',
  facilityAmountPlaceholder: 'Facility amount',
  currency: 'Currency',
  currencyPlaceholder: 'Currency',
  interestRate: 'Interest Rate',
  interestRatePlaceholder: 'Interest Rate',
  facilityType: 'Facility Type',
  facilityTypePlaceholder: 'Facility Type',
  facilityDuration: 'Facility Duration',
  facilityDurationPlaceholder: 'Facility Duration',
  gracePeriod: 'Grace period',
  gracePeriodPlaceholder: 'Grace period',
  facilityPurpose: 'Facility purpose',
  facilityPurposePlaceholder: 'Facility purpose',
  total: 'Total',
  totalPlaceholder: 'Total',
  previousFacilities: 'Previous Facilities',
  addPreviousFacilities: 'Add Previous Facilities',
  utilized: 'Utilized',
  utilizedPlaceholder: 'Utilized',
  facilityDate: 'Facility date',
  facilityDatePlaceholder: 'Facility date',
  arrears: 'Arrears',
  arrearsPlaceholder: 'Arrears',
  status: 'Status',
  statusPlaceholder: 'Status',
  settlementDate: 'Settlement date',
  settlementDatePlaceholder: 'Settlement date',
  guarantee: 'Guarantee',
  guaranteePlaceholder: 'Guarantee',
  cbePositionIscore: 'Bankinng Inquires (CBE Position / Iscore)',
  addCbePositionIscore: 'Add Bankinng Inquires (CBE Position / Iscore)',
  saveAsDraft: 'Save As Draft',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  deleteConfirmation: 'Are you sure you want to delete this asset?',
  saveConfirmation: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  noCurrentFacilitiesAddedMessage: 'No Current Facilities Added',
  noPreviousFacilitiesAddedMessage: 'No Previous Facilities Added',
  noBankingInquiresAddedMessage: 'No Banking inquires(CBE Position/iScore Added',
};
const projectionPage = {
  progressSpinnerAriaLabel: 'Custom ProgressSpinner',
  projectionFormTitle: 'Projection Form',
  tabRepayment: 'Repayment',
  tabProjectionAssumptions: 'Projection Assumptions',
  tabLoanRunoff: 'Loan Runoff',
  tabFixedAssetProjection: 'Fixed Asset Projection',
  yearsSetupDialogHeader: 'Years Setup',
  addNewYearDialogHeader: 'Add New Year',
  startYearLabel: 'Start Year',
  selectYearPlaceholder: 'Select Year',
  periodsLabel: 'Periods',
  periodsPlaceholder: 'Periods',
  lastYearIsInterimLabel: 'Last Year is Interim',
  yesLabel: 'Yes',
  noLabel: 'No',
  endWithMonthLabel: 'End with month',
  selectPlaceholder: 'Select',
  addButtonLabel: 'Add',
  validationMessageStartYear: 'Please enter a valid start year.',
  validationMessagePeriods: 'Please enter a valid number of periods.',
  warningMessageInterimYear: 'Please note that the last entered year was Interim, and you need to update it!',
  submit: 'Submit',
  cancel: 'Cancel',
  // Repayment Tap
  claimData: 'Claim Data',
  disbursementDate: 'Disbursement Date:',
  leaseDetails: 'Lease Details:',
  rentPeriod: 'Rent Period:',
  leaseRentType: 'Lease Rent Type:',
  firstRentDue: 'First Rent Due:',
  assetCost: 'Asset Cost:',
  percentOfFinance: 'Percent Of Finance %:',
  percentOfResidualValue: 'Percent Of Residual Value %:',
  benchMark: 'Bench Mark %:',
  spread: 'Spread %:',
  termInYears: 'Term In Years:',
  downPayment: 'Down Payment:',
  netFinancedAmount: 'Net Financed Amount:',
  residualValue: 'Residual Value:',
  decliningInterestRate: 'Declining Interest Rate:',
  rentAmount: 'Rent Amount:',
  totalRents: 'Total Rents :',
  grossFinancialIncome: 'Gross Financial Income :',
  flatInterestRate: 'Flat Interest Rate :',
  confirm: 'Confirm',
  areYouSureYouWantToSave: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  year: 'Year',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnnual: 'Semi Annual',
  inArrear: 'In Arrear',
  inAdvance: 'In Advance',
  // Loan Runoff tap
  loanTitle: 'Loan Runoff',
  normal: 'Normal',
  stress: 'Stress',
  saveConfirmation: 'Are you sure you want to save?',
  applyFirstValue: 'Apply first value to rest',
  // Fixed Assets Tap
  fixedAssetTaptitle: 'Fixed Asset Projection',
  // Projection Assumptions Tap
  normalScenario: 'Normal Scenario',
  stressScenario: 'Stress Scenario',
  title: 'Title',
};

const guaranteePage = {
  guarantee: 'Guarantee',
  addGuarantor: 'Add Guarantor',
  typeOfGuarantee: 'Type of Guarantee',
  documentationOfWarrantyOwnership: 'Documentation of warranty ownership',
  typeOfFacility: 'Type of facility',
  coveringTheValueOfTheGuaranteeForFinancing: 'Covering the value of the guarantee for financing',
  abilityToDisposeOfTheGuarantee: 'The ability to dispose of the guarantee (the speed of converting it into cash)',
  extentToWhichValueOfGuaranteeChanged: 'The extent to which the value of the guarantee has changed',
  percentageAcceptedAsCollateral: 'The percentage accepted as collateral',
  marketValue: 'Market value',
  netAcceptableValue: 'Net acceptable value',
  total: 'Total',
  facilityAmount: 'Facility amount',
  facilityAmountPlaceholder: 'Facility amount',
  uncoveredAmount: 'Uncovered amount',
  uncoveredAmountPlaceholder: 'Uncovered amount',
  coveredAmount: 'Covered amount',
  lgd: 'LGD',
  lgdPlaceholder: 'LGD',
  pd: 'PD',
  pdPlaceholder: 'PD',
  el: 'EL',
  elPlaceholder: 'EL',
  elPercentage: 'EL %',
  elPercentagePlaceholder: 'EL %',
  inCaseOfPersonalGuarantees: 'In the case of personal guarantees',
  atLeastOneGuarantorShouldBeEntered: 'At least one Guarantor should be entered!',
  name: 'Name',
  tradeName: 'Trade name',
  activityType: 'Activity type',
  activityDuration: 'Activity Duration',
  activityAddressInDetail: 'Activity address in detail',
  commercialRegistrationNo: 'Commercial Registration No',
  homeAddress: 'Home Address',
  workPlace: 'Work Place',
  workAddress: 'Work Address',
  netSalary: 'Net Salary',
  telephone: 'Telephone',
  guarantorRelationshipWithClient: 'Guarantor\'s relationship with the client',
  guarantorReputation: 'Guarantor reputation',
  saveAsDraft: 'Save As Draft',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  deleteConfirmation: 'Are you sure you want to delete this asset?',
  saveConfirmation: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  select: 'Select',
  noGuaranteesAddedMessage: 'No Guarantees Added',
  noPersonalGuaranteesAddedMessage: 'No Personal Guarantees Added',
  idNumber: 'ID Number',
  addPersonalGuarantor: 'Add Personal Guarantor',
};

const creditPage = {
  // Historical Page
  createFinancialInfo: 'Create Financial Info',
  createProjection: 'Create Projection',
  createOrr: 'Create ORR',
  guarantee: 'Guarantee',
  facilities: 'Facilities',
  createCreditMemo: 'Create Credit Memo',
  creditForm: 'Credit Form',
  addPeriod: 'Add Period',
  yearsSetup: 'Years Setup',
  addNewYear: 'Add New Year',
  startYear: 'Start Year',
  periods: 'Periods',
  lastYearIsInterim: 'Last Year Is Interim',
  endWithMonth: 'End With Month',
  add: 'Add',
  companyName: 'Company Name',
  auditorName: 'Auditor Name',
  cbeListedOrNot: 'CBE Listed Or Not',
  incomeStatement: 'Income Statement',
  assets: 'Assets',
  liabEquity: 'Liab & OE',
  cashFlowStatement: 'Cash Flow Statement',
  factSheet: 'Fact Sheet',
  cancel: 'Cancel',
  submit: 'Submit',
  // Income Statement Page
  incomeStatementTitle: 'Income Statement (EGP \'000s)',
  year: 'Year',
  selectYear: 'Select Year',
  net_sales: 'Net Sales',
  cost_of_goods_sold: 'Cost of Goods Sold',
  depreciation: 'Depreciation',
  gross_profit: 'Gross Profit',
  admin_depreciation: 'Admin Depreciation',
  sales_general_admin_expense: 'Sales General Admin Expense',
  provisions: 'Provisions',
  research_development_expenses: 'Research & Development Expenses',
  goodwill_amortization: 'Goodwill Amortization',
  other_amortization: 'Other Amortization',
  net_operating_profit: 'Net Operating Profit',
  gross_interest_exp_exist: 'Gross Interest Expense Existing',
  interest_expense_new: 'Interest Expense New',
  interest_income: 'Interest Income',
  divided_income: 'Dividend Income',
  sundry_income_expense_fx_exchange: 'Sundry Income/Expense FX Exchange',
  sundry_income_expense: 'Sundry Income/Expense',
  net_profit_before_tax: 'Net Profit Before Tax',
  total_tax_expense: 'Total Tax Expense',
  current_tax_expense: 'Current Tax Expense',
  deferred_tax_expense: 'Deferred Tax Expense',
  net_profit_after_tax: 'Net Profit After Tax',
  sale_of_plant: 'Sale of Plant',
  net_profit_after_unusual_items: 'Net Profit After Unusual Items',
  other_unusual_item: 'Other Unusual Item',
  common_stock_dividends: 'Common Stock Dividends',
  other_adjustment: 'Other Adjustment',
  total_change_in_re: 'Total Change in Retained Earnings',
  // Assets Page
  assetsPageTitle: 'Assets (EGP \'000s)',
  cash_and_cash_equivalent: 'Cash & Cash Equivalent',
  marketable_securities: 'Marketable Securities',
  account_receivables: 'Account Receivables',
  allowances: 'Allowances',
  net_account_receivables: 'Net Account Receivables',
  raw_materials: 'Raw Materials',
  work_in_progress: 'Work in Progress',
  finished_goods: 'Finished Goods',
  goods_in_transit: 'Goods in Transit',
  total_inventory: 'Total Inventory',
  advance_payments_plus_prepaids: 'Advance Payments plus Prepaids',
  current_due_from_shareholders: 'Current Due from Shareholders',
  other_current_asset: 'Other Current Asset',
  total_current_assets: 'Total Current Assets',
  land: 'Land',
  building_and_improvement: 'Building and Improvement',
  equipment: 'Equipment',
  construction_in_progress: 'Construction in Progress',
  other: 'Other',
  gross_plant: 'Gross Plant',
  less_accumulated_depreciation: 'Less Accumulated Depreciation',
  net_property_plant_equipment: 'Net Property Plant Equipment',
  investments: 'Investments',
  due_from_shareholders: 'Due from Shareholders',
  other_tangible_asset: 'Other Tangible Asset',
  deferred_tax_asset: 'Deferred Tax Asset',
  long_term_prepaids: 'Long Term Prepaids',
  total_other_tangible_assets: 'Total Other Tangible Assets',
  goodwill: 'Goodwill',
  less_accum_amortization: 'Less Accumulated Amortization',
  net_intangibles: 'Net Intangibles',
  total_fixed_assets: 'Total Fixed Assets',
  total_assets: 'Total Assets',
  less_accumulated_dep: 'Less Accumulated dep',
  // Liab & OE Page
  liabEquityPageTitle: 'Liabilities & OE (EGP \'000s)',
  short_term_borrowing_existing: 'Short-term Borrowing (Existing)',
  short_term_borrowing_new: 'Short-term Borrowing (New)',
  current_portion_senior_debt_existing: 'Current Portion of Senior Debt (Existing)',
  accounts_payable: 'Accounts Payable',
  taxes_payable: 'Taxes Payable',
  accrued_expenses: 'Accrued Expenses',
  interest_payable: 'Interest Payable',
  dividends_payable: 'Dividends Payable',
  down_payments: 'Down Payments',
  due_to_sister_companies: 'Due to Sister Companies',
  other_current_liability: 'Other Current Liabilities',
  total_current_liabilities: 'Total Current Liabilities',
  long_term_lease_obligation: 'Long-term Lease Obligation',
  long_term_lease_existing: 'Long-term Lease (Existing)',
  long_term_senior_debt_new: 'Long-term Senior Debt (New)',
  long_term_sub_debt_existing: 'Long-term Subordinated Debt (Existing)',
  long_term_sub_debt_new: 'Long-term Subordinated Debt (New)',
  other_non_current_liabilities: 'Other Non-current Liabilities',
  due_to_owners: 'Due to Owners',
  total_long_term_debt: 'Total Long-term Debt',
  total_liabilities: 'Total Liabilities',
  deferred_taxes: 'Deferred Taxes',
  minority_interest: 'Minority Interest',
  other_grey_area_item: 'Other Grey Area Items',
  total_grey_area: 'Total Grey Area',
  preferred_stock: 'Preferred Stock',
  common_stock: 'Common Stock',
  additional_paid_in_capital: 'Additional Paid-in Capital',
  retained_earnings_opening: 'Retained Earnings (Opening)',
  net_income: 'Net Income',
  dividends: 'Dividends',
  retained_earnings_closing: 'Retained Earnings (Closing)',
  ongoing_owners_account: 'Ongoing Owners Account',
  legal_reverse: 'Legal Reverse',
  fx_adjustment: 'Foreign Exchange Adjustment',
  other_equity_reverses: 'Other Equity Reverses',
  total_equity: 'Total Equity',
  total_liab_and_equity: 'Total Liabilities and Equity',
  reconcile_bs: 'Reconcile Balance Sheet',
  new_money_need_short: 'New Money Needed (Short-term)',
  new_money_need_long: 'New Money Needed (Long-term)',
  // Cash Flow Statement Page
  cashFlowStatementPageTitle: 'Cash Flow Statement (EGP \'000s)',
  refresh: 'Refresh',
  exportExcel: 'Export Excel',
  npaui: 'Npaui',
  interest_expense: 'Interest Expense',
  gain_loss_on_sale_of_plant: 'Gain Loss On Sale Of Plant',
  tax_payable: 'Taxes Payable',
  tax_paid: 'Taxes Paid',
  nopat: 'Nopat',
  goodwill_and_other_amort: 'Goodwill And Other Amort',
  copat: 'Copat',
  change_in_account_receivables: 'Change In Account Receivables',
  change_in_inventory: 'Change In Inventory',
  advance_payments_and_prepaids: 'Advance Payments+ Prepaids',
  change_in_account_payables: 'Change In Account Payables',
  change_accrued_expenses: 'Change Accrued Expenses',
  change_in_downpayments: 'Change In Down Payments',
  net_change_in_working_investment: 'Net Change in Working Investment',
  change_in_marketable_securities: 'Change In Marketable Securities',
  change_in_interest_payable: 'Change In Interest Payable',
  other_current_liability_and_due_from_shareholders: 'Other Current Liability Due From Shareholders',
  cash_after_curr_operations: 'Cash After Curr Operations',
  interest_expense_neg: 'Interest Expense',
  current_portion_of_long_term_debt_existing: 'Current Portion Of Long Term Debt Existing',
  short_term_loan: 'Short Term Loan',
  total_financing_payments: 'Total Financing Payments',
  cash_before_lt_uses: 'Cash Before lt Uses',
  net_plant_expenditures: 'Net Plant Expenditures',
  interest_income_reverse: 'Interest Income',
  divided_income_reverse: 'Dividend Income',
  sundry_income_expense_fx_exchange_reverse: 'Sundry Income (Expense) Fx Exchange',
  sundry_income_expense_reverse: 'Sundry Income Expense',
  gain_loss_on_sale_of_plant_reverse: 'Gain Loss On Sale Of Plant',
  other_unusual_item_reverse: 'Other Unusual Item',
  cash_before_financing: 'Cash Before Financing',
  change_in_short_term_debt: 'Change In Short Term Debt',
  change_in_long_term_debt: 'Change In Long Term Debt',
  change_in_lease: 'Change In Lease',
  change_in_equity: 'Change In Equity',
  net_cash_flow: 'Net Cash Flow',
  cads: 'Cads',
  ds: 'Ds',
  nopat_financing_payments: 'Nopat Financing Payments',
  copat_financing_payments: 'Copat Financing Payments',
  cads_ds: 'Cads Ds',
  // Fact Sheet Page
  factSheetPageTitle: 'Fact Sheet',
  depont_formula: 'DUPONT FORMULA',
  profitability: 'PROFITABILITY',
  asset_efficiency: 'ASSET EFFICIENCY',
  liquidity_and_leverage: 'LIQUIDITY & LEVERAGE',
  ret_on_sales: 'Ret On Sales Npat/Sales',
  asset_util: 'Asset Util. Sales/Ta',
  total_assets_equity_ratio: 'Total Assets/Equity',
  return_on_equity: 'Return On Equity ',
  return_on_assets: 'Return On Assets',
  sales_growth_rate: 'Sales Growth Rate',
  cogs_sales_ratio: 'Cogs/Sales',
  gross_profit_sales_ratio: 'Gross Profit/Sales',
  sg_and_a_sales_ratio: 'Sg&A/Sales',
  provisions_sales_ratio: 'Provision /Sales',
  research_and_development_expenses_sales_ratio: 'Research & Development Expenses /Sales',
  operating_profit_margin: 'Operating Profit Margin',
  net_profit_before_tax_sales_ratio: 'Net Profit Before Tax /Sales',
  net_profit_after_tax_sales_ratio: 'Net Profit After Tax/Sales',
  net_profit_after_unusual_items_sales_ratio: 'Net Profit After Unusual Items Npaui /Sales',
  working_investment: 'Working Investment ',
  change_in_working_investment: '% Change Working Investment',
  working_investment_revenue_ratio: 'Working Invest./Revenue',
  account_receivables_days_on_hand: 'Account Receivables Days On Hand',
  account_payables_days_on_hand: 'Account Payables Days On Hand',
  total_inventory_days: 'Total Inventory Days',
  raw_materials_inv_days: 'Raw Material Inv Days',
  wip_inv_days: 'Wip Inv Days',
  fg_inv_days: 'Fg Inv Days',
  accrued_exp_days: 'Accrued Exp Days',
  down_payments_doh: 'Down Payments Doh',
  advance_payments_doh: 'Advance Payments Doh',
  acc: 'ACC',
  current_ratio: 'Current Ratio',
  net_working_capital: 'Net Working Capital',
  quick_ratio: 'Quick Ratio',
  coverage_ratio: 'Coverage Ratio Ca/Tl',
  total_liabilities_net_worth_ratio: 'Tot Liabilities/Net Worth',
  interest_coverage_ebit: 'Interest Coverage:  Ebit',
  debt_assets_ratio: 'Debt_Assets',
  account_payable_days_on_hand: 'Account Payable Days On Hands',
  // end of Fact Sheet Page
  noInvestigationReportFound: 'No Investigation report found.',
  loadingInvestigationData: 'Loading investigation data. Please wait.',
  serialNumber: 'Serial Number',
  tradeName: 'Trade Name',
  actionHeader: 'Action',
  requiredDocuments: 'Required Documents',
  viewInvestigation: 'View Investigation',
  viewCreditHistoricalDetail: 'View Credit Historical Details',
  startCreditMemo: 'Start Credit Memo',
  creditMemoNo: 'Credit Memo No.',
  authorityOfApproval: 'Authority of Approval',
  approvalDate: 'Approval Date',
  putposeOfMemo: 'Purpose of The Memo',
  creditMemo: 'Credit Memo',
};
const riskPage = {
  noCreditReportFound: 'No Credit report found.',
  loadingCreditData: 'Loading credit data. Please wait.',
  companyName: 'Company Name',
  serialNumber: 'Serial Number',
  actionHeader: 'Action',
  tradeName: 'Trade Name',
  requiredDocuments: 'Required Documents',
  viewInvestigation: 'View Investigation',
  viewCreditHistoricalDetail: 'View Credit Historical Details',
  viewProjection: 'View Projection',
  startCreditRiskMemo: 'Start Credit Risk Memo',
  // Repayment Page
  claimData: 'Claim Data',
  leaseDetails: 'Lease Details',
  disbursementDate: 'Disbursement Date',
  rentPeriod: 'Rent Period',
  leaseRentType: 'Lease Rent Type',
  firstRentDue: 'First Rent Due',
  assetCost: 'Asset Cost',
  percentOfFinance: 'Percent Of Finance %',
  percentOfResidualValue: 'Percent Of Residual Value %',
  benchMark: 'Bench Mark %',
  spread: 'Spread %',
  termInYears: 'Term In Years',
  downPayment: 'Down Payment',
  netFinancedAmount: 'Net Financed Amount',
  residualValue: 'Residual Value',
  decliningInterestRate: 'Declining Interest Rate',
  rentAmount: 'Rent Amount',
  totalRents: 'Total Rents',
  grossFinancialIncome: 'Gross Financial Income',
  flatInterestRate: 'Flat Interest Rate',
  confirm: 'Confirm',
  areYouSureYouWantToSave: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  submit: 'Submit',
  cancel: 'Cancel',
  // Projection Assumption Table
  userType: 'userType',
  credit: 'Credit',
  admin: 'Admin',
  risk: 'Risk',
  normalScenario: 'Normal Scenario',
  title: 'Title',
  normal: 'Normal',
  stressScenario: 'Stress Scenario',
  stress: 'Stress',
  // Loan RunOff Table
  loanRunoff: 'Loan Runoff',
  applyFirstValueToRest: 'Apply first value to rest',
  loanInputNormal: 'loan-input normal',
  loanInputStress: 'loan-input stress',
  // Projection Assumption Table
  mainTitleNormalScenario: 'Normal Scenario',
  mainTitleStressScenario: 'Stress Scenario',
  mainTitleLoanRunoff: 'Loan Runoff',
  confirmationContent: 'Are you sure you want to save?',
  confirmationDialogHeader: 'Confirm',
  buttonNo: 'No',
  buttonYes: 'Yes',
  buttonSubmit: 'Submit',
  buttonCancel: 'Cancel',
  buttonApplyFirstValueToRest: 'Apply first value to rest',
};
const riskMemoForm = {
  creditRiskForm: 'Credit Risk Form',
  creditMemoNo: 'Credit Memo No.',
  authorityOfApproval: 'Authority of Approval',
  approvalDate: 'Approval Date',
  putposeOfMemo: 'Purpose of The Memo',
  saveAsDraft: 'Save As Draft',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  deleteConfirmation: 'Are you sure you want to delete this Item?',
  saveConfirmation: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  // Company & Request information Tab
  companyAndRequestInformation: 'Company & Request information',
  clientInformation: 'Client Information',
  date: 'Date',
  automaticGeneration: 'Automatic generation',
  companyName: 'Company Name',
  tradeName: 'Trade Name',
  relationshipSince: 'Relationship since',
  selectYear: 'Select Year',
  address: 'Address',
  governorate: 'Governorate',
  select: 'Select',
  ownershipOfBusinessBuilding: 'Ownership of Business building',
  legalForm: 'Legal form',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  realActivity: 'Real Activity',
  classificationInFi: 'Classification in the FI. Current clients only',
  applicationNumber: 'Application number',
  branch: 'Branch',
  businessStartingDate: 'Business starting date',
  authorizedCapital: 'Authorized capital',
  paidInCapital: 'Paid in capital',
  commercialRegisterNumber: 'Commercial register number',
  commercialRegisterDate: 'Commercial register Date',
  commercialRegisterRenewalDate: 'Commercial register renewal Date',
  operationalLicenseNumber: 'Operational license number',
  endDateOfLicense: 'End date of license',
  numberOfEmployees: 'Number of employees',
  applicationDate: 'Application date',
  orrMoodysRatingCurrent: 'ORR Moody’s Rating Current',
  orrMoodysRatingExpected: 'ORR Moody’s Rating Expected',

  // Facility Structure Tab
  facilityStructure: 'Facility Structure',
  facilityInformation: 'Facility Information',
  currentFacility: 'Current Facility',
  currentLimits: 'Current limits',
  outstanding: 'Outstanding',
  listOfFacilities: 'List of Facilities',
  totalLimit: 'Total Limit',
  purposeClarification: 'Purpose Clarification',
  pricingPercentage: 'Pricing %',
  pricing: 'Pricing',
  proposedFeesPercentage: 'Proposed Fees %',
  proposedFees: 'Proposed Fees',
  drawDownPeriod: 'Draw Down Period',
  downPayment: 'Down paymnet',
  tenor: 'Tenor',
  expiryDate: 'Expiry Date',
  sourceOfRepayment: 'Source of Repayment',
  collateral: 'Collateral',
  guarantees: 'Guarantees',
  dealMechanism: 'Deal Mechanism',
  financedAssetsDetails: 'Financed Assets Details',
  assetToBeFinanced: 'Asset to be finnanced',
  assetType: 'Asset Type',
  assetDescription: 'Asset Description',
  originationCountry: 'Organization Country',
  assetCondition: 'Asset Status',
  profitability: 'Profitability',
  specialTerms: 'Special Terms',
  specialTerm: 'Special Term',
  atLeastOneTermShouldBeEntered: 'At least one Term should be entered!',
  newTermInfo: 'New Term info',
  addSpecialTerm: 'Add Special Term',
  noTermsToShow: 'No Terms to Show',
  noTermsAddedYet: 'No Terms added yet',
  totalFacilityAmount: 'Total Facility Amount',
  // Company’s profile and background Tab
  companyProfileAndBackground: 'Company’s profile and background',
  dealInformation: 'Deal Information',
  companyProfileBackground: 'Company profile & background',
  shareholdersStructure: 'Shareholders Structure',
  namesOfPartnersShareholders: 'Names of Partners / Shareholders',
  title: 'Title',
  percentageOfOwnership: '% of ownership',
  amount: 'Amount',
  educationalLevel: 'Educational Level',
  yearsOfExperience: 'Years of experience',
  ownerBirthdate: 'Owner Birthdate',
  ownerAge: 'Owner Age',
  // Investigation Summary Tab
  industryAnalysisLabel: 'Industry Analysis',
  // Financial Analysis Tab
  financialAnalysis: 'Financial Analysis',
  mainFinancialIndicator: 'Main Financial Indicator',
  salesAndProfitability: 'Sales And Profitability',
  salesAndProfitabilityComments: 'Sales And Profitability Comments',
  assetsEfficiency: 'Assets’ Efficiency',
  assetsEfficiencyComments: 'Assets’ Efficiency Comments',
  liquidityLeverageAndCapitalStructure: 'Liquidity, Leverage & Capital Structure',
  liquidityLeverageAndCapitalStructureComments: 'Liquidity, Leverage & Capital Structure Comments',
  cashflow: 'Cashflow',
  projectionTable: 'Projection Table',
  projectionComments: 'Projection Comments',
  normal: 'Normal',
  ok: 'OK',
  investigationSummaryTabHeader: 'Investigation Summary',
  summaryOfInvestigationReportHeader: 'Summary of the investigation report about the client',
  reputationInMarketLabel: 'Reputation in the market',
  situationWithBanksLabel: 'Situation with banks',
  bankruptcySituationLabel: 'Bankruptcy situation',
  taxSituationLabel: 'Tax Situation',
  insuranceSituationLabel: 'Insurance Situation',
  protestSituationLabel: 'Protest situation',
  investigationCommentLabel: 'Investigation Comment',
  creditCommentLabel: 'Credit Comment',
  iscoreReportHeader: 'I-Score Report',
  iscoreNameHeader: 'Name',
  iscoreNumberOfBanksHeader: 'Number Of banks',
  iscoreRatingHeader: 'I-Score rating',
  iscoreSituationOfNegativeListHeader: 'Situation of Negative list',
  iscoreCommentsPlaceholder: 'iScore Comments',
  positivePoints: 'Positive points',
  negativePoints: 'Negative points',
  cbePositionHeader: 'CBE Position',
  cbeTypeOfLoanHeader: 'Type of Loan',
  cbeAuthorizedHeader: 'Authorized',
  cbeOutstandingHeader: 'Outstanding',
  cbePercentageHeader: '%',
  totalLabel: 'Total',
  totalAuthorizedFacilitiesPlaceholder: 'Total Authorized Facilities',
  totalUsedFacilitiesPlaceholder: 'Total Used Facilities',
  operationalCycleHeader: 'Operational cycle',
  operationalCycleLabel: 'Operational cycle',
  keyCustomersHeader: 'Key Customers',
  keyCustomersNameHeader: 'Name',
  keyCustomersTypeHeader: 'Customer Type',
  keyCustomersSalesHeader: 'Sales %',
  keyCustomersPaymentTermsHeader: 'Payment Terms & Conditions (Key Customers)',
  keyCustomersLocalHeader: 'Local',
  keyCustomersExportHeader: 'Export',
  keyCustomersSalesPercentageHeader: 'Sales %',
  keySuppliersHeader: 'Key Suppliers',
  keySuppliersNameHeader: 'Name',
  keySuppliersTypeHeader: 'Supplier Type',
  keySuppliersPurchasesHeader: 'Purchases %',
  keySuppliersPaymentTermsHeader: 'Payment Terms & Conditions (Key Suppliers)',
  keySuppliersLocalHeader: 'Local',
  keySuppliersExportHeader: 'Export',
  keySuppliersPurchasesPercentageHeader: 'Purchases %',
  inventoryPolicyHeader: 'Inventory Policy',
  inventoryPolicyLabel: 'Inventory Policy',
  negative: 'Negative',
  positive: 'Positive',
  numberOfBanks: 'Number of Banks',
  // Recommendations Tab
  recommendationsTabHeader: 'Recommendations',
  recommendationsLabel: 'Recommendations',
  nameHeader: 'Name',
  titleHeader: 'Title',
  decisionHeader: 'Decision',
  commentsHeader: 'Comments',
  actionHeader: 'Action',
  creditMemoTeamRecommendationsHeader: 'Credit Memo Team Recommendations',
  // Portfolio Analysis Tab
  portfolioAnalysis: 'Portfolio Analysis',
  concentrationPerIndustry: 'Concentration per industry',
  numberOfClientsWithinSameIndsutry: 'Number of clients within same indsutry',
  perfromingWithinSameIndsutry: 'Non-perfroming % within same indsutry',
  averagePricingForSameIndustry: 'Average pricing for same industry',
  // Conclusion Tab
  conclusion: 'Conclusion',
  conclusions: 'Conclusions',
  // cashFlowTitles
  cash_after_curr_operations: 'Cash from operating activities',
  cash_before_financing: 'Cash before Financing',
  net_cash_flow: 'Net Cash Flow',
  nopat_financing_payments: 'Normalized Operating Profit after Tax (NOPAT)',
  copat_financing_payments: 'Current Operating Profit after Tax (COPAT)',
  // Main Financial Indicator Table
  net_sales: 'Sales',
  cogs_sales_ratio: 'Cost of Goods Sold (COGS)/Sales (%)',
  sales_growth_rate: 'Sales growth rate (%)',
  gross_profit_sales_ratio: 'Gross Profit Margin %',
  net_profit_after_tax_sales_ratio: 'Net Profit Margin %',
  net_profit_after_tax: 'Net Profit',
  current_ratio: 'Current Ratio',
  total_liabilities_net_worth_ratio: 'Leverage',
  return_on_equity: 'Return On Equity',
  return_on_assets: 'Return on Assets',
  total_inventory_days: 'Inventory Days On Hands',
  account_receivables_days_on_hand: 'Account Receivables Days On Hands',
  account_payable_days_on_hand: 'Account Payable Days On Hands',
};
const creditMemoPage = {
  // Company & Request information Tab
  companyAndRequestInformation: 'Company & Request information',
  clientInformation: 'Client Information',
  date: 'Date',
  automaticGeneration: 'Automatic generation',
  companyName: 'Company Name',
  tradeName: 'Trade Name',
  relationshipSince: 'Relationship since',
  selectYear: 'Select Year',
  address: 'Address',
  governorate: 'Governorate',
  select: 'Select',
  ownershipOfBusinessBuilding: 'Ownership of Business building',
  legalForm: 'Legal form',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  realActivity: 'Real Activity',
  classificationInFi: 'Classification in the FI. Current clients only',
  applicationNumber: 'Application number',
  branch: 'Branch',
  businessStartingDate: 'Business starting date',
  authorizedCapital: 'Authorized capital',
  paidInCapital: 'Paid in capital',
  commercialRegisterNumber: 'Commercial register number',
  commercialRegisterDate: 'Commercial register Date',
  commercialRegisterRenewalDate: 'Commercial register renewal Date',
  operationalLicenseNumber: 'Operational license number',
  endDateOfLicense: 'End date of license',
  numberOfEmployees: 'Number of employees',
  applicationDate: 'Application date',
  orrMoodysRatingCurrent: 'ORR Moody’s Rating Current',
  orrMoodysRatingExpected: 'ORR Moody’s Rating Expected',

  // Facility Structure Tab
  facilityStructure: 'Facility Structure',
  facilityInformation: 'Facility Information',
  currentFacility: 'Current Facility',
  currentLimits: 'Current limits',
  outstanding: 'Outstanding',
  listOfFacilities: 'List of Facilities',
  totalLimit: 'Total Limit',
  purposeClarification: 'Purpose Clarification',
  pricingPercentage: 'Pricing %',
  pricing: 'Pricing',
  proposedFeesPercentage: 'Proposed Fees %',
  proposedFees: 'Proposed Fees',
  drawDownPeriod: 'Draw Down Period',
  downPayment: 'Down paymnet',
  tenor: 'Tenor',
  expiryDate: 'Expiry Date',
  sourceOfRepayment: 'Source of Repayment',
  collateral: 'Collateral',
  guarantees: 'Guarantees',
  dealMechanism: 'Deal Mechanism',
  financedAssetsDetails: 'Financed Assets Details',
  assetToBeFinanced: 'Asset to be finnanced',
  assetType: 'Asset Type',
  assetDescription: 'Asset Description',
  originationCountry: 'Organization Country',
  assetCondition: 'Asset Status',
  profitability: 'Profitability',
  specialTerms: 'Special Terms',
  atLeastOneTermShouldBeEntered: 'At least one Term should be entered!',
  newTermInfo: 'New Term info',
  addSpecialTerm: 'Add Special Term',
  noTermsToShow: 'No Terms to Show',
  noTermsAddedYet: 'No Terms added yet',
  totalFacilityAmount: 'Total Facility Amount',
  // Company’s profile and background Tab
  companyProfileAndBackground: 'Company’s profile and background',
  dealInformation: 'Deal Information',
  companyProfileBackground: 'Company profile & background',
  shareholdersStructure: 'Shareholders Structure',
  namesOfPartnersShareholders: 'Names of Partners / Shareholders',
  title: 'Title',
  percentageOfOwnership: '% of ownership',
  amount: 'Amount',
  educationalLevel: 'Educational Level',
  yearsOfExperience: 'Years of experience',
  ownerBirthdate: 'Owner Birthdate',
  ownerAge: 'Owner Age',
  // Investigation Summary Tab
  industryAnalysisLabel: 'Industry Analysis',
  // Financial Analysis Tab
  financialAnalysis: 'Financial Analysis',
  salesAndProfitability: 'Sales And Profitability',
  salesAndProfitabilityComments: 'Sales And Profitability Comments',
  assetsEfficiency: 'Assets’ Efficiency',
  assetsEfficiencyComments: 'Assets’ Efficiency Comments',
  liquidityLeverageAndCapitalStructure: 'Liquidity, Leverage & Capital Structure',
  liquidityLeverageAndCapitalStructureComments: 'Liquidity, Leverage & Capital Structure Comments',
  cashflow: 'Cashflow',
  projectionTable: 'Projection Table',
  projectionComments: 'Projection Comments',
  normal: 'Normal',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  saveAsDraft: 'Save as draft',
  confirmDelete: 'Are you sure you want to delete this Item?',
  confirmSave: 'Are you sure you want to save?',
  no: 'No',
  yes: 'Yes',
  ok: 'OK',
  investigationSummaryTabHeader: 'Investigation Summary',
  summaryOfInvestigationReportHeader: 'Summary of the investigation report about the client',
  reputationInMarketLabel: 'Reputation in the market',
  situationWithBanksLabel: 'Situation with banks',
  bankruptcySituationLabel: 'Bankruptcy situation',
  taxSituationLabel: 'Tax Situation',
  insuranceSituationLabel: 'Insurance Situation',
  protestSituationLabel: 'Protest situation',
  investigationCommentLabel: 'Investigation Comment',
  creditCommentLabel: 'Credit Comment',
  iscoreReportHeader: 'I-Score Report',
  iscoreNameHeader: 'Name',
  iscoreNumberOfBanksHeader: 'Number Of banks',
  iscoreRatingHeader: 'I-Score rating',
  iscoreSituationOfNegativeListHeader: 'Situation of Negative list',
  iscoreCommentsPlaceholder: 'iScore Comments',
  cbePositionHeader: 'CBE Position',
  cbeTypeOfLoanHeader: 'Type of Loan',
  cbeAuthorizedHeader: 'Authorized',
  cbeOutstandingHeader: 'Outstanding',
  cbePercentageHeader: '%',
  totalLabel: 'Total',
  totalAuthorizedFacilitiesPlaceholder: 'Total Authorized Facilities',
  totalUsedFacilitiesPlaceholder: 'Total Used Facilities',
  operationalCycleHeader: 'Operational cycle',
  operationalCycleLabel: 'Operational cycle',
  keyCustomersHeader: 'Key Customers',
  keyCustomersNameHeader: 'Name',
  keyCustomersTypeHeader: 'Customer Type',
  keyCustomersSalesHeader: 'Sales %',
  keyCustomersPaymentTermsHeader: 'Payment Terms & Conditions (Key Customers)',
  keyCustomersLocalHeader: 'Local',
  keyCustomersExportHeader: 'Export',
  keyCustomersSalesPercentageHeader: 'Sales %',
  keySuppliersHeader: 'Key Suppliers',
  keySuppliersNameHeader: 'Name',
  keySuppliersTypeHeader: 'Supplier Type',
  keySuppliersPurchasesHeader: 'Purchases %',
  keySuppliersPaymentTermsHeader: 'Payment Terms & Conditions (Key Suppliers)',
  keySuppliersLocalHeader: 'Local',
  keySuppliersExportHeader: 'Export',
  keySuppliersPurchasesPercentageHeader: 'Purchases %',
  inventoryPolicyHeader: 'Inventory Policy',
  inventoryPolicyLabel: 'Inventory Policy',
  negative: 'Negative',
  positive: 'Positive',
  numberOfBanks: 'Number of Banks',
  // Recommendations Tab
  recommendationsTabHeader: 'Recommendations',
  recommendationsLabel: 'Recommendations',
  nameHeader: 'Name',
  titleHeader: 'Title',
  decisionHeader: 'Decision',
  commentsHeader: 'Comments',
  actionHeader: 'Action',
  creditMemoTeamRecommendationsHeader: 'Credit Memo Team Recommendations',
  // New Translation
  companyRequestInfo: 'Company & Request information',
  // ales And Profitability Table
  net_sales: 'Sales',
  sales_growth_rate: 'Sales growth rate (%)',
  cogs_sales_ratio: 'Cost of Goods Sold (COGS)/Sales (%)',
  gross_profit: 'Gross Profit',
  gross_profit_sales_ratio: 'Gross Profit Margin %',
  sg_and_a_sales_ratio: 'Sales, General and Administrative expenses',
  net_profit_after_tax: 'Net Profit',
  net_profit_after_tax_sales_ratio: 'Net Profit Margin %',
  total_equity: 'Total Equity',
  return_on_equity: 'Return On Equity',
  // Assets’ Efficiency Table
  working_investment: 'Working Investment (EGP000s)',
  working_investment_revenue_ratio: 'Working Investment / SALES %',
  account_receivables_days_on_hand: 'Account Receivables Days On Hands',
  total_inventory_days: 'Inventory Days On Hands',
  account_payable_days_on_hand: 'Account payable Days on Hands',
  total_assets: 'Total Assets',
  return_on_assets: 'Return on Assets',
  // Liquidity, Leverage & Capital Structure Table
  current_ratio: 'Current Ratio',
  net_working_capital: 'Net Working Capital',
  total_liabilities_net_worth_ratio: 'Leverage',
  short_term_debt: '% SHORT TERM DEBT',
  spontaneous_financing: '% SPONTANEOUS FINANCING',
  long_term_debt: '% LONG TERM DEBT',
  net_worth: '% NET WORTH',
  other_liabilities: '% OTHER LIABILITIES',
  other_grey_area: '% OTHER (GREY AREA)',
  // CashFlow Table
  cash_after_curr_operations: 'Cash from operating activities',
  cash_before_financing: 'Cash before Financing',
  net_cash_flow: 'Net Cash Flow',
  nopat_financing_payments: 'Normalized Operating Profit after Tax (NOPAT)',
  copat_financing_payments: 'Current Operating Profit after Tax (COPAT)',
};
const headerCards = {
  edit: 'Edit',
  create: 'Create',
  financialInfo: 'Financial Information',
  projection: 'Projection',
  orr: 'ORR',
  gurantee: 'Gurantee',
  facilities: 'Facilities',
  creditMemo: 'Credit Memo',
};
const months = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};

const messages = {
  yourMessage: 'Your Message',
  noData: 'No Data',
  loading: 'Loading...',
  error: 'Error',
  success: 'Success',
  required: 'This field is required',
  searchPlaceholder: 'Keyword Search',
  registerNo: 'Register No.',
  tradeName: 'Trade Name.',
  requests: 'Requests',
  actions: 'Actions',
  generalInfo: 'General Information',
  requestDate: 'Request Date',
  customerNumber: 'Customer Number',
  branch: 'Branch',
  companyName: 'Company Name',
  contactPerson: 'Contact Person',
  contactPersonNumber: 'Contact Person Number',
  contactPersonEmail: 'Contact Person Email',
  responsibleRm: 'Responsible RM',
  activityData: 'Activity Data',
  businessStartDate: 'Business Start Date',
  numberOfEmployees: 'Number Of Employees',
  numberOfBranches: 'Number Of Branches',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  realActivity: 'Real Activity',
  otherActivities: 'Other activities',
  activityLicenseNumber: 'Activity License Number',
  lastRenewalDate: 'Last Renewal Date',
  licenseExpireDate: 'License Expire Date',
  companyLegalStructure: 'Company Legal Structure',
  ownershipInformation: 'Ownership Information',
  companyInformation: 'Company Information',
  activityStreet: 'Activity Street',
  governorate: 'Governorate',
  directorate: 'Directorate',
  district: 'District',
  neighbor: 'Neighbor',
  activityHousingOwnership: 'Activity Housing Ownership',
  commercialRegisterNumber: 'Commercial Register Number',
  activityLicenseExpireDate: 'Activity License Expire Date',
  activityLicenseRenewalDate: 'Activity License Renewal Date',
  activityLicenseRenewalStatus: 'Activity License Renewal Status',
  activityLicenseRenewalComment: 'Activity License Renewal Comment',
  activityLicenseRenewalFile: 'Activity License Renewal File',
  activityLicenseRenewalFileComment: 'Activity License Renewal File Comment',
  activityLicenseRenewalFileDate: 'Activity License Renewal File Date',
  activityLicenseRenewalFileStatus: 'Activity License Renewal File Status',
  activityLicenseRenewalFileFile: 'Activity License Renewal File File',
  atLeastOnePartner: 'At Least One Partner Should Be Added!',
  saveAsDraft: 'Save as draft',
  confirmDelete: 'Are you sure you want to delete this partner?',
  confirmSave: 'Are you sure you want to save?',
  // Management &Employees Tab
  management: 'Management',
  yearsOfExperienceInFieldForOwners: 'How many years of experience in the same field for owners?',
  comment: 'Comment',
  currentNumberOfEmployees: 'What is the current number of employees?',
  permanentEmployeeNumber: 'Permanent Employee Number',
  seasonalEmployeeNumber: 'Seasonal Employee Number',
  username: 'Username',
  email: 'Email',
  status: 'Status',
  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',

  ownerName: 'Owner Name',
  percentage: 'Percentage',
  ownerPercentage: 'Percentage',
};
const customers = {
  customers: 'Customers',
  totalCustomers: 'Total Customers',
  noCustomersFound: 'No customers found.',
  loadingCustomers: 'Loading customers data. Please wait.',
};

const primevueConfig = {
  startsWith: 'Starts with',
  contains: 'Contains',
  notContains: 'Not contains',
  endsWith: 'Ends with',
  equals: 'Equals',
  notEquals: 'Not equals',
  noFilter: 'No Filter',
  lt: 'Less than',
  lte: 'Less than or equal to',
  gt: 'Greater than',
  gte: 'Greater than or equal to',
  dateIs: 'Date is',
  dateIsNot: 'Date is not',
  dateBefore: 'Date is before',
  dateAfter: 'Date is after',
  clear: 'Clear',
  apply: 'Apply',
  matchAll: 'Match All',
  matchAny: 'Match Any',
  addRule: 'Add Rule',
  removeRule: 'Remove Rule',
  accept: 'Yes',
  reject: 'No',
  choose: 'Choose',
  upload: 'Upload',
  cancel: 'Cancel',
  completed: 'Completed',
  pending: 'Pending',
  fileSizeTypes: 'B_KB_MB_GB_TB_PB_EB_ZB_YB',
  dayNames: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday',
  dayNamesShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat',
  monthNames: 'January_February_March_April_May_June_July_August_September_October_November_December',
  monthNamesShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec',
  dayNamesMin: 'Su_Mo_Tu_We_Th_Fr_Sa',
  chooseYear: 'Choose Year',
  chooseMonth: 'Choose Month',
  chooseDate: 'Choose Date',
  prevDecade: 'Previous Decade',
  nextDecade: 'Next Decade',
  prevYear: 'Previous Year',
  nextYear: 'Next Year',
  prevMonth: 'Previous Month',
  nextMonth: 'Next Month',
  prevHour: 'Previous Hour',
  nextHour: 'Next Hour',
  prevMinute: 'Previous Minute',
  nextMinute: 'Next Minute',
  prevSecond: 'Previous Second',
  nextSecond: 'Next Second',
  am: 'am',
  pm: 'pm',
  today: 'Today',
  weekHeader: 'Wk',
  firstDayOfWeek: '0',
  showMonthAfterYear: 'false',
  dateFormat: 'mm/dd/yy',
  weak: 'Weak',
  medium: 'Medium',
  strong: 'Strong',
  passwordPrompt: 'Enter a password',
  searchMessage: '{0} results are available',
  selectionMessage: '{0} items selected',
  emptySelectionMessage: 'No selected item',
  emptySearchMessage: 'No results found',
  fileChosenMessage: '{0} files',
  noFileChosenMessage: 'No file chosen',
  emptyMessage: 'No available options',
  aria: {
    trueLabel: 'True',
    falseLabel: 'False',
    nullLabel: 'Not Selected',
    star: '1 star',
    stars: '{star} stars',
    selectAll: 'All items selected',
    unselectAll: 'All items unselected',
    close: 'Close',
    previous: 'Previous',
    next: 'Next',
    navigation: 'Navigation',
    scrollTop: 'Scroll Top',
    moveTop: 'Move Top',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    moveBottom: 'Move Bottom',
    moveToTarget: 'Move to Target',
    moveToSource: 'Move to Source',
    moveAllToTarget: 'Move All to Target',
    moveAllToSource: 'Move All to Source',
    pageLabel: 'Page {page}',
    firstPageLabel: 'First Page',
    lastPageLabel: 'Last Page',
    nextPageLabel: 'Next Page',
    prevPageLabel: 'Previous Page',
    rowsPerPageLabel: 'Rows per page',
    jumpToPageDropdownLabel: 'Jump to Page Dropdown',
    jumpToPageInputLabel: 'Jump to Page Input',
    selectRow: 'Row Selected',
    unselectRow: 'Row Unselected',
    expandRow: 'Row Expanded',
    collapseRow: 'Row Collapsed',
    showFilterMenu: 'Show Filter Menu',
    hideFilterMenu: 'Hide Filter Menu',
    filterOperator: 'Filter Operator',
    filterConstraint: 'Filter Constraint',
    editRow: 'Row Edit',
    saveEdit: 'Save Edit',
    cancelEdit: 'Cancel Edit',
    listView: 'List View',
    gridView: 'Grid View',
    slide: 'Slide',
    slideNumber: '{slideNumber}',
    zoomImage: 'Zoom Image',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    rotateRight: 'Rotate Right',
    rotateLeft: 'Rotate Left',
  },
};

const mainReport = {
  title: 'Main Report',
  selectColumns: 'Select Columns',
  clear: 'Clear Filters',
  export: 'Download',
  noData: 'No customers found.',
  customerNumber: 'Customer Number',
  companyName: 'Company Name',
  approvalType: 'Approval Type',
  approvalAmount: 'Approval Amount',
  approvalDate: 'Approval Date',
  expiryDate: 'Expiry Date',
  daysInArrears: 'Arrears',
  status: 'Status',
  searchById: 'Search by id',
  searchByCompanyName: 'Search by company name',
  searchByApprovalType: 'Search by Approval Type',
  datePlaceholder: 'mm/dd/yyyy',
  any: 'Any',
  typeOfApproval: 'Type of Approval',
  total: 'Total Approved Amount:',
};
const detailedReport = {
  title: 'Detailed Report',
  selectColumns: 'Select Columns',
  clear: 'Clear Filters',
  export: 'Download',
  noData: 'No customers found.',
  customerNumber: 'Customer Number',
  companyName: 'Company Name',
  governorate: 'Governorate',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  numberOfApprovals: 'Number of Approvals',
  totalAmount: 'Total Amount',
  outstanding: 'Outstanding',
  daysInArrears: 'Arrears',
  percentOfPortfolio: '% of Portfolio',
  status: 'Status',
  searchById: 'Search by id',
  searchByCompanyName: 'Search by company name',
  searchByIndustry: 'Search by Industry',
  searchBySector: 'Search by Sector',
  searchBySubIndustry: 'Search by Sub Industry',
  searchByApprovals: 'Search by Approvals',
  searchByAmount: 'Search by Amount',
  searchByDaysInArrears: 'Search by Days in Arrears',
  searchByOutstanding: 'Search by Outstanding',
  searchByPercent: 'Search by Percent of Portfolio',
  datePlaceholder: 'mm/dd/yyyy',
  any: 'Any',
  totalNumberOfApprovals: 'Total Number of Approvals:',
  totalAmounts: 'Total Amounts:',
  totalOutstanding: 'Total Outstanding:',
  totalPercentOfPortfolio: 'Total Percentage of Portfolio:',
};
const industryConcentrationReport = {
  title: 'Industry Concentration Report',
  selectColumns: 'Select Columns',
  clear: 'Clear Filters',
  export: 'Download',
  noData: 'No Data found.',
  numberOfCustomers: 'Number of Customers',
  industry: 'Industry',
  totalAmount: 'Approved Amount',
  sectorCapLimit: 'Sector Cap Limit',
  outstanding: 'Outstanding',
  percentOfPortfolio: '% of Portfolio',
  percentageOfSectorCapLimit: 'Percentage of Sector Cap Limit',
  searchById: 'Search by number of customers',
  searchByCompanyName: 'Search by company name',
  searchByIndustry: 'Search by Industry',
  searchBySector: 'Search by Sector Cap Limit',
  searchBySectorCapPercent: 'Search by Sector Cap Percent',
  searchBySubIndustry: 'Search by Sub Industry',
  searchByAmount: 'Search by Amount',
  searchByOutstanding: 'Search by Outstanding',
  searchByPercent: 'Search by Percent of Portfolio',
  datePlaceholder: 'mm/dd/yyyy',
  any: 'Any',
  totalNumberOfCustomers: 'Total Number of Customers:',
  totalAmounts: 'Total Amounts:',
  totalOutstanding: 'Total Outstanding:',
};
const orrForm = {
  mainTitle: 'ORR Report',
  performanceIndicators: 'PERFORMANCE INDICATORS',
  strengthOfFinancialPosition: 'STRENGTH OF FINANCIAL POSITION',
  revenueGrowth: 'Revenue Growth',
  grossProfitMargin: 'Gross Profit Margin',
  netProfitMargin: 'Net Profit Margin',
  netProfitBeforeTax: 'Net profit before interest and tax / financing expenses',
  currentRatio: 'Current ratio',
  leverage: 'Leverage',
  riskDegree: 'Risk Degree',
  riskRating: 'Risk Rating',
  totalScore: 'Total Score',
  confirmHeader: 'Confirm',
  confirmationMessage: 'Are you sure you want to save?',
  dropdownPlaceholder: 'Please select',
  submit: 'Submit',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  progressSpinner: 'Custom ProgressSpinner',
  select: 'Select',
  selectYear: 'Select Year',
  // sections
  leasedAssetQuality: 'Leased Asset Quality',
  industry: 'Industry',
  institution: 'Institution',
  management: 'Management',
  competition: 'Competition',
  facilitiesOutstandingBalances: 'Facilities / Outstanding Balances',
  cashFlow: 'Cash Flow',
  // Parameters
  assetType: 'Asset Type',
  downPaymentSecurityMargin: 'Down payment (Security Margin)',
  assetConditionUsedNew: 'Asset condition (Used / New)',
  isTheAssetStandardOrCustomized: 'Is the asset standard or customized ?',
  industryStability: 'Industry stability',
  profitability: 'Profitability',
  seasonality: 'Seasonality',
  governmentRegulations: 'Government Regulations',
  businessDURATION: 'BUSINESS DURATION',
  legalFORM: 'LEGAL FORM',
  managementStructure: 'Management Structure',
  seniorManagementExperience: 'Senior Management Experience',
  thePracticeOfDelegationAuthoritySecondLineManagement: 'The Practice of Delegation Authority / Second Line Management',
  companyPlan: 'Company Plan',
  productDiversification: 'Product Diversification',
  customerBase: 'Customer Base',
  supplierBase: 'Supplier Base',
  clientSCustomersProfileWhoAreThey: 'Client\'s customers profile (Who are they)',
  facilitiesWithBanksOrOthers: 'Facilities with Banks or Others',
  relationWithClientsSuppliers: 'Relation with Clients & Suppliers',
  shareholdersSupportInCaseOfDifficulties: 'Shareholders support in case of Difficulties',
  netCashFlowsFromOperatingActivitiesNOPAT: 'Net cash flows from operating activities (NOPAT)',
  netCashFlowsFromOperatingActivitiesCOPAT: 'Net cash flows from operating activities (COPAT)',
  netCashFlowsInterestAndPremiumsDueDuringTheYear: 'Net cash flows / interest and premiums due during the year',
  // Options
  commercialOrAdminBuilding: 'Commercial Or Admin Building',
  industrialLandOrBuilding: 'Industrial (Land or building)',
  commercialVehicles: 'Commercial Vehicles',
  passengerVehicles: 'Passenger Vehicles',
  trucks: 'Trucks',
  heavyEquipment: 'Heavy Equipment',
  machinesAndProductionLines: 'Machines and Production lines',
  equipments: 'Equipments',
  downPaymentMoreThanAKELCPolicy: 'Down payment more than AKELC policy',
  lessThanAKELCPolicy: 'Less than AKELC Policy',
  new: 'New',
  used: 'Used',
  standard: 'Standard',
  partiallyCustomized: 'Partially customized',
  highlyCustomized: 'Highly customized',
  highStability: 'High stability',
  stableAndGrowing: 'Stable and growing',
  exposedToFluctuationsDueToSurroundingFactors: 'Exposed to fluctuations due to surrounding factors',
  theIndustryIsExposedToAdverseConditionsAndThereAreRisksOfDeterioration: 'The industry is exposed to adverse conditions and there are risks of deterioration',
  continuingDeterioration: 'Continuing deterioration',
  consistentlyProfitableDuringRecessionExpansion: 'Consistently profitable during recession & expansion',
  profitabilityDuringExpansionButMildlyUnprofitableDuringRecession: 'Profitability during expansion but mildly unprofitable during recession',
  unprofitableDuringRecessionAndExpansion: 'Unprofitable during recession and expansion',
  lowSeasonality: 'Low Seasonality',
  moderateSeasonality: 'Moderate Seasonality',
  highSeasonality: 'High Seasonality',
  regulatorProtectsOrEnhancesIndustry: 'Regulator protects or enhances industry',
  noGovernmentRegulations: 'No Government regulations',
  regulationsHaveSignificantAdverseImpactOnIndustry: 'Regulations have significant adverse impact on industry',
  newCompanyStartup: 'New company (startup)',
  newCompanyAndExtensionForCurrentBusiness: 'New company and extension for current business',
  durationOfBusinessFrom3To5Years: 'Duration of business from 3 to 5 years',
  moreThe5Years: 'More than 5 years',
  soleProprietorship: 'Sole Proprietorship',
  limitedPartnership: 'Limited Partnership',
  generalPartnership: 'General Partnership',
  jointStock: 'Joint Stock',
  individualManagement: 'Individual management',
  familyManagement: 'Family management',
  theMultiplicityOfPartnersInvolvedInTheManagementOfTheActivity: 'The multiplicity of partners involved in the management of the activity',
  professionalStructuredManagement: 'Professional Structured Management',
  recentExperienceLessThan2Years: 'Recent Experience (less than 2 years)',
  acceptableExperienceMoreThan2YearsTo5Years: 'Acceptable Experience (more than 2 years to 5 years)',
  goodExperienceMoreThan5YearsTo10Years: 'Good Experience (more than 5 years to 10 years)',
  highExperienceMoreThan10Years: 'High Experience (more than 10 years)',
  delegationOfAuthority: 'Delegation of Authority',
  secondLinePresentButDecisionMakingIsCentralized: 'Second line present but decision making is centralized',
  centralizedDecisionMakingAbsenceOfSecondLine: 'Centralized decision making & absence of second line',
  theCompanyHasAPlansFor3Years: 'The company has plans for 3 years',
  theCompanyHasAPlanForOneYear: 'The company has a plan for one year',
  theCompanyHasNoClearPlanning: 'The company has no clear planning',
  high: 'High',
  moderate: 'Moderate',
  weakOrLackOfCapacityToDiversifyProductsAndServices: 'Weak or lack of capacity to diversify products and services',
  theCompanyHasABroadAndDiversifiedClientBase: 'The company has a broad and diversified client base',
  theCompanyTurnoverIsConcentratedOnFewMajorClients: 'The company turnover is concentrated on few major clients',
  theCompanyHasOneOrTwoClientsOnly: 'The company has one or two clients only',
  theCompanyHasABroadAndDiversifiedBaseOfSuppliers: 'The company has a broad and diversified base of suppliers',
  theCompanyHasExclusiveTwoOrThreeSuppliers: 'The company has exclusive two or three suppliers',
  theCompanyIsStronglyDependentOnAUniqueSupplier: 'The company is strongly dependent on a unique supplier',
  wellKnown: 'Well-known',
  notWellKnown: 'Not well-known',
  badReputation: 'Bad Reputation',
  regular: 'Regular',
  noBalance: 'No Balance',
  lateDuesMoreThanThreeMonths: 'Late dues more than three months',
  regularWithArrears: 'Regular with arrears',
  irregularTransactions: 'Irregular Transactions',
  regularTransactions: 'Regular transactions',
  noAvailableInformation: 'No Available information',
  theCompanyEnjoyTheSupportOfItsMotherCoSisterCoShareholdersEtc: 'The company enjoys the support of its mother co., sister co., shareholders, etc.',
  theCompanyDoesNotEnjoyAnySupport: 'The company does not enjoy any support',
  sufficientToCoverWorkingCapitalAndLongTermInvestment: 'Sufficient to cover working capital and long-term investment',
  sufficientToCoverEitherWorkingCapitalOrLongTermInvestmentOnly: 'Sufficient to cover either working capital or long-term investment only',
  negativeCashFlowsInThePreviousTwoPhases: 'Negative cash flows in the previous two phases',
  sufficientAtHighAndStableRatesAtLeast2Times: 'Sufficient at high and stable rates (at least 2 times)',
  sufficientAtGoodAndStableRatesGreaterThan1Time: 'Sufficient at good and stable rates (greater than 1 time)',
  sufficientAtAcceptableAndStableRatesOnceStable: 'Sufficient at acceptable and stable rates (once stable)',
  insufficientBelow1: 'Insufficient (below 1)',
};
const dashboardPage = {
  totalCustomers: 'Total Customers',
  newCustomersInNovember: 'in November',
  targetAchieved: 'Target Achieved',
  totalRequests: 'Total Requests',
  submittedRequests: 'Submitted',
  draftRequests: 'Draft',
  totalInvestigations: 'Total Investigation',
  approvedInvestigations: 'Approved',
  rejectedInvestigations: 'Rejected',
  draftCustomers: 'Draft Customers',
  customerName: 'Customer Name',
  submittedDate: 'Submitted Date',
  achievementProgress: 'Achievement Progress',
  businessAchievedGoals: 'Business Achieved Goals',
  businessTotalTarget: 'Business Total Target 1200',
  investigationAchievedGoals: 'Investigation Achieved Goals',
  investigationTotalTarget: 'Investigation Total Target 800',
  finishedRequests: 'Finished Requests',
  totalRequests963: 'Total Requests 963',
  businessDepartmentAchievement: 'Business Department Achievement',
  draftInvestigations: 'Draft Investigation',
  investigationId: 'Investigation ID',
  completedPercentage: 'Completed %',
  notification: 'Notification',
  today: 'TODAY',
  addedNewCustomers: 'Added 3 new customers',
  rejectedRequests: 'has been rejected',
  yesterday: 'YESTERDAY',
  submittedInvestigations: 'has submitted 5 investigations',
  approvedRequests: 'Approved 4 requests.',

  totalInvestigation: 'Total Investigation',
  inNovember: 'In November',
  submitted: 'Submitted',
  draft: 'Draft',
  approved: 'Approved',
  rejected: 'Rejected',
  draftInvestigation: 'Draft Investigation',
  new: 'New',
};
const microCreditPage = {
  microCreditForm: 'Micro Credit Form',
  companyName: 'Company Name',
  noName: 'No Name',
  auditorName: 'Auditor Name',
  cbeListedOrNot: 'CBE Listed or not',
  assets: 'Assets',
  fixedAssets: 'Fixed Assets',
  liabilities: 'Liabilities',
  equity: 'Equity',
  submit: 'Submit',
  cancel: 'Cancel',
  yearsSetup: 'Years Setup',
  startYear: 'Start Year',
  selectYear: 'Select Year',
  select: 'Select',
  periods: 'Periods',
  lastYearIsInterim: 'Last Year is Interim',
  endWithMonth: 'End with month',
  add: 'Add',
  addNewYear: 'Add New Year',
  year: 'Year',
  yearIsInterim: 'Year is Interim',
  pleaseNote: 'Please note that the last entered year was Interim, and you need to update it!',
  confirm: 'Confirm',
  areYouSureToSave: 'Are you sure you want to save?',
  yes: 'Yes',
  no: 'No',
  // Asset Panel
  assetsFormTitle: 'Assets Form',
  addYearLabel: 'Add Year',
  yearLabel: 'Year',
  yearNumberPlaceholder: 'Year number',
  totalLabel: 'Total',
  deleteLabel: 'Delete',
  itemLabel: 'Item',
  nameLabel: 'Name',
  balanceLabel: 'Balance',
  currencyLabel: 'Currency',
  commentsLabel: 'Comments',
  equivalentLabel: 'Equivalent',
  evidenceLabel: 'Evidence',
  dateLabel: 'Settlement Date',
  arrearsLabel: 'Arrears',
  quantityLabel: 'Quantity',
  unitLabel: 'Unit',
  unitPriceLabel: 'Unit Price',
  cashAndBankAccountsTitle: 'Cash and bank accounts',
  addRowLabel: 'Add Row',
  selectCurrencyPlaceholder: 'Select Currency',
  evidenceConditionPlaceholder: 'Evidence condition',
  commentsPlaceholder: 'Comments',
  debtorAccountTitle: 'Debtor Account',
  inventoryTitle: 'Inventory',
  noYearsAddedMessage: 'No Years Added',
  // Fixed Assets
  fixedAssetsTitle: 'Fixed Assets',
  landAndBuildingTitle: 'Land and building',
  selectOwnershipPlaceholder: 'Select Ownership',
  purchaseDatePlaceholder: 'Purchase Date',
  deleteHeader: 'Delete',
  carsAndVehiclesTitle: 'Cars and vehicles',
  furnitureEquipmentFixturesTitle: 'Furniture & Equipment & Fixtures',
  description: 'Description',
  area: 'The unit in square meters',
  evidence: 'Ownership Evidence',
  ownership: 'Ownership',
  property: 'The type of property',
  residence: 'Customers residence',
  income: 'Is the property income generating?',
  registration: 'Registration in the Land Registry',
  market: 'The market value of the property',
  book: 'The book value of the property',
  depreciation: 'Total Depreciation',
  date: 'The date of purchase',
  comments: 'Comments',
  vehicleDescription: 'Vehicle description (model)',
  vehicleType: 'the type of the vehicle',
  status: 'Status',
  item: 'Item',
  type: 'Type',
  // Liabilities
  addYear: 'Add Year',
  liabilitiesToBanks: 'Liabilities to banks or financial institutions',
  addRow: 'Add Row',
  selectCurrency: 'Select Currency',
  startDate: 'Start Date',
  selectEvidence: 'Select Evidence',
  delete: 'Delete',
  creditorsAndSuppliers: 'Creditors and suppliers',
  noYearsAdded: 'No Years Added',
  liabilitiesToBanksOrFinancialInstitutionsName: 'The name of the entity',
  liabilitiesToBanksOrFinancialInstitutionsOutstanding: 'Current Outstanding',
  liabilitiesToBanksOrFinancialInstitutionsCurrency: 'Currency',
  liabilitiesToBanksOrFinancialInstitutionsEquivalent: 'Equivalent',
  liabilitiesToBanksOrFinancialInstitutionsEvidence: 'Evidence',
  liabilitiesToBanksOrFinancialInstitutionsStartDate: 'Loan start date',
  liabilitiesToBanksOrFinancialInstitutionsTerm: 'Loan term',
  liabilitiesToBanksOrFinancialInstitutionsMonthly: 'Monthly installment value',
  liabilitiesToBanksOrFinancialInstitutionsComments: 'Comments',
  creditorsAndSuppliersName: 'Name',
  creditorsAndSuppliersType: 'Type',
  creditorsAndSuppliersBalance: 'Current balance',
  creditorsAndSuppliersEvidence: 'Evidence',
  creditorsAndSuppliersStatement: 'The supplier\'s statement about the customer',
  creditorsAndSuppliersComments: 'Comments',
  // Equity
  equityPageTitle: 'Equity',
  equityPageAddYearLabel: 'Add Year',
  equityPageYearLabel: 'Year',
  equityPageYearPlaceholder: 'Year number',
  equityPageEquityTitle: 'Equity',
  equityPageAddRowLabel: 'Add Row',
  equityPageSelectCurrency: 'Select Currency',
  equityPageDeleteLabel: 'Delete',
  equityPageNoYearsAdded: 'No Years Added',
  assetType: 'Asset Type',
};

const incomeStatementForm = {
  incomeStatementForm: 'Income Statement Form',
  addYear: 'Add Year',
  year: 'Year',
  name: 'Name',
  total: 'Total',
  value: 'Value',
  salesOfRevenue: 'Revenue',
  otherRevenue: 'Other Revenue',
  salesReturnsAndAllowances: 'Sales returns and allowances',
  totalRevenue: 'total revenue',
  beginingOfInventory: 'Begining of Inventory',
  netPurchases: 'net purchases',
  endOfInventory: 'End of Inventory',
  cogs: 'COGS',
  grossProfit: 'Gros Profit',
  salaries: 'Salaries',
  rent: 'Rent',
  salesExpenses: 'Sales expenses',
  licensingFeesAndConsultingServices: 'Licensing fees and consulting services',
  advertising: 'Advertising',
  printedMatter: 'Printed matter',
  electricityWaterAndTelephone: 'Electricity, water and telephone',
  insurance: 'Insurance',
  fuelTransportationAndMaintenance: 'Fuel, transportation and maintenance',
  otherOperatingExpenses: 'Other operating expenses',
  otherExpenses: 'Other expenses',
  personalAndFamilyExpenses: 'Personal and family expenses',
  totalOperatingExpenses: 'Total operating expenses',
  netProfitBeforeTax: 'Net profit before tax',
  tax: 'Tax',
  netProfit: 'Net profit',
  noYearsAddedMessage: 'No Years Added',
};
const cashflowForm = {
  addYear: 'Add Year',
  yearNumber: 'Year Number',
  year: 'Year',
  name: 'Name',
  total: 'Total',
  value: 'Value',
  listOfHistoricalCashflows: 'List of historical cashflows',
  listOfProjectedCashflows: 'List of projected cashflows',

  netCashBalanceBeginningOfEachMonth: 'Net cash balance (beginning of each month)',
  salesInCash: 'Sales In Cash',
  revenueCollectedFromDebtors: 'Revenue collected from debtors',
  otherRevenue: 'Other revenue',
  salesReturnAndAllowance: 'Sales return and allowance',
  totalIncomeCash: 'Total income cash',
  totalCashAvailable: 'Total Cash Available',
  inventoryAtTheBeginningOfPeriod: 'Inventory at the beginning of period',
  purchasesInCash: 'Purchases in cash',
  inventoryAtTheEndOfPeriod: 'Inventory at the end of period',
  salaries: 'Salaries',
  rent: 'Rent',
  salesExpenses: 'Sales Expenses',
  licensingFeesAndConsultingServices: 'Licensing fees and consulting services',
  advertising: 'Advertising',
  printedMatter: 'Printed matter',
  electricityWaterAndTelephone: 'Electricity, Water and Telephone',
  insurance: 'Insurance',
  fuelTransportationAndMaintenance: 'Fuel, Transportation and maintenance',
  otherOperatingExpenses: 'Other Operating Expenses',
  personalAndFamilyExpenses: 'Personal and Family Expenses',
  tax: 'Tax',
  totalOperatingCashFlows: 'Total operating cash flows',
  purchaseOfFixedAssets: 'Purchase of Fixed Assets',
  otherStartupCosts: 'Other Startup costs',
  installmentsAndLoansPayments: 'Installments and loans payments',
  paymentOfSuppliers: 'Payment of suppliers',
  partnerWithdrawals: 'Partner withdrawals',
  totalCashflowInvestingAndFinancing: 'Total Cashflow (Investing and Financing)',
  totalOutflows: 'Total Outflows',
  netCashBalanceEndOfEachMonth: 'Net Cash balance (End of each month)',
  theValueOfTheNewFacility: 'The value of the new facility',
  totalCashReceived: 'Total cash received',
  additionalDiscretionaryPurchases: 'Additional discretionary purchases',
  estimatedAdditionalOperatingExpenses: 'Estimated additional operating expenses',
  buyingNewAssetsOrImplementingNewProjects: 'Buying new assets or implementing new projects',
  paymentOfNewObligationsFinancingSuppliers: 'Payment of new obligations (financing + suppliers)',
  newLoanPayments: 'New loan payments',
  partnerWithdrawalsFuture: 'Partner withdrawals (future)',
  increaseOfSales: 'Increase Of Sales %',
  noYearsAddedMessage: 'No Years Added',
};

const requiredDocuments = {
  main_title: 'Required Documents',
  update: 'Update',
  upload: 'Upload',
  show_files: 'Show Files',
  delete: 'Delete',
  confirm_delete_partner: 'Are you sure you want to delete this partner?',
  confirm_delete_files: 'Are you sure you want to delete those files?',
  confirm_save: 'Are you sure you want to save?',
  confirm_header: 'Confirm',
  yes: 'Yes',
  no: 'No',
  show_files_header: 'Show files',
  ok: 'Ok',
};
const committeePage = {
  committee_list: 'Committee List',
  keyword_search: 'Keyword Search',
  no_credit_report_found: 'No Credit report found.',
  loading_credit_risk_data: 'Loading credit risk data. Please wait.',
  id: 'ID',
  company_name: 'Company Name',
  serial_number: 'Serial Number',
  trade_name: 'Trade Name',
  action: 'Action',
  required_documents: 'Required Documents',
  view_investigation: 'View Investigation',
  view_credit_historical_details: 'View Credit Historical Details',
  view_projection: 'View Projection',
  view_credit_risk_memo: 'View Credit Risk Memo',
  risk_committee: 'Risk Committee',
  // committee Form
  formTitle: 'Committees',
  decisionTableTitle: 'Decision Table',
  name: 'Name',
  title: 'Title',
  decision: 'Decision',
  comments: 'Comments',
  commentPlaceholder: 'Comment',
  actionInForm: 'Action',
  approved: 'Approved',
  rejected: 'Rejected',
};
const requestMonitor = {
  requestMonitorTitle: 'Request Monitor',
  keywordSearch: 'Keyword Search',
  noRequestsFound: 'No requests history found.',
  loadingRequests: 'Loading requests history. Please wait.',
  serialNumber: 'Serial Number',
  tradeName: 'Trade Name',
  companyName: 'Company Name',
  action: 'Action',
  showHistory: 'Show history',
  viewTimeline: 'View timeline',
  // Rqeuest History
  requestHistoryTitle: 'Request History',
  keywordSearchInHistory: 'Keyword Search',
  noRequestsFoundInHistory: 'No requests history found.',
  loadingRequestsInHistory: 'Loading requests history. Please wait.',
  serialNumberInHistory: 'Serial Number',
  requestDate: 'Request Date',
  username: 'Username',
  email: 'Email',
  requestStatus: 'Request Status',
  lastChangeDate: 'Last Change Date',
};
const timeLine = {
  milestoneInfo: 'Information about this milestone',
  readMore: 'Read more',
  businessStatus: 'Business',
  investigationStatus: 'Investigation',
  creditStatus: 'Credit',
  riskStatus: 'Risk',
  businessTeam: 'Business Team',
  investigationTeam: 'Investigation Team',
  creditTeam: 'Credit Team',
  riskTeam: 'Risk Team',
};
const reportCenter = {
  mainTitle: 'Reporting Center',
  reporting: 'Reporting',
  keywordSearch: 'Keyword Search',
  noRequests: 'No Customer report found.',
  loadingCustomers: 'Loading Customers data. Please wait.',
  id: 'ID',
  companyName: 'Company Name',
  serialNumber: 'Serial Number',
  tradeName: 'Trade Name',
  action: 'Action',
  goToReports: 'Go To Reports',
  // Report Center Details
  supplierInfo: 'Supplier Information',
  investigationReport: 'Investigation Report',
  historicalInfoReport: 'Historical Information Report',
  cashflow: 'Cashflow',
  factSheet: 'Fact Sheet',
  creditMemoReport: 'Credit Memo Report',
  creditRiskMemoReport: 'Credit Risk Memo Report',
  projection: 'Projection',
};
const charts = {
  chartsTitle: 'Charts',
  requestsCountPerFacilityType: 'Requests Count Per Facility Type',
  clientsPerSector: 'Clients Per Sector',
  facilityMaturity: 'Facility Maturity',
  clientSize: 'Client Size',
  portfolioGrowthChart: 'Portfolio Growth Chart',
};
const guaranteeForm = {
  mainTitle: 'Guarantee',
  addGuarantor: 'Add Guarantor',
  guaranteePanel: 'Guarantee',
  typeOfGuarantee: 'Type of Guarantee',
  warrantyOwnership: 'Documentation of warranty ownership',
  typeOfFacility: 'Type of facility',
  coveringValue: 'Covering the value of the guarantee for financing',
  abilityToDispose: 'The ability to dispose of the guarantee (the speed of converting it into cash)',
  valueChange: 'The extent to which the value of the guarantee has changed',
  acceptedCollateral: 'The percentage accepted as collateral',
  marketValue: 'Market value',
  netAcceptableValue: 'net acceptable value',
  total: 'Total',
  facilityAmount: 'Facility amount',
  uncoveredAmount: 'uncovered amount',
  coveredAmount: 'covered amount',
  LGD: 'LGD',
  PD: 'PD',
  EL: 'EL',
  ELperse: 'EL %',
  personalGuarantees: 'In the case of personal guarantees',
  atLeastOneGuarantor: 'At least one Guarantor should be entered!',
  name: 'Name',
  tradeName: 'Trade name',
  activityType: 'Activity type',
  activityDuration: 'Activity Duration',
  activityAddress: 'Activity address in detail',
  commercialRegistrationNo: 'Commercial Registration No',
  homeAddress: 'Home Address',
  workPlace: 'Work Place',
  workAddress: 'Work Address',
  netSalary: 'Net Salary',
  telephone: 'Telephone',
  guarantorClientRelationship: 'Guarantor\'s relationship with the client',
  guarantorReputation: 'Guarantor reputation',
  deleteConfirmation: 'Are you sure you want to delete this asset?',
  saveConfirmation: 'Are you sure you want to save?',
  selectPlaceholder: 'Select',
  percentageCollateralPlaceholder: 'The percentage accepted as collateral %',
  marketValuePlaceholder: 'Market value',
  netAcceptableValuePlaceholder: 'net acceptable value',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  submit: 'Submit',
  saveAsDraft: 'Save As Draft',
  inCaseTradeCompany: 'In case the guarantor owns a business',
  inCaseEmployee: 'In case the guarantor is an employee',
};
const facilitiesDetails = {
  CurrentFacility: 'Current Facility',
  AddCurrentFacility: 'Add Current Facility',
  FacilityAmount: 'Facility amount',
  Currency: 'Currency',
  InterestRate: 'Interest Rate',
  FacilityType: 'Facility Type',
  FacilityDuration: 'Facility Duration',
  GracePeriod: 'grace period',
  FacilityPurpose: 'Facility purpose',
  Total: 'Total',
  PreviousFacilities: 'Previuos Facilities',
  AddPreviousFacilities: 'Add Previuos Facilities',
  Utilized: 'utilized',
  FacilityDate: 'Facilty date',
  Arrears: 'Arrears',
  Status: 'Status',
  SettlementDate: 'Settlement date',
  Guarantee: 'Guarantee',
  CBEPosition: 'Bankinng Inquires (CBE Position / Iscore)',
  SaveAsDraft: 'Save As Draft',
  Submit: 'Submit',
  Cancel: 'Cancel',
  ConfirmDelete: 'Are you sure you want to delete this asset?',
  ConfirmSave: 'Are you sure you want to save?',
  No: 'No',
  Yes: 'Yes',
};
const sidebar = {
  // Menu Headers
  home: 'Home',
  customer: 'Customer',
  managment: 'Managment',
  reportingCenter: 'Reporting Center',
  charts: 'Charts',
  // Menu Items
  dashboard: 'Dashboard',
  newCustomers: 'New Customer',
  customersList: 'Customers List',
  addRequest: 'Adding Request',
  incomeStatement: 'Income Statement',
  cashflowStatement: 'Cashflow Statement',
  microCreditForm: 'Micro Credit Form',
  guarantees: 'Guarantees',
  facilities: 'Facilities',
  investigationList: 'Investigation List',
  creditList: 'Credit List',
  riskList: 'Risk List',
  committeeList: 'Committee List',
  requestMonitor: 'Request Monitor',
  timeline: 'Timeline',
  temporaryReport: 'Temporary Report',
  mainReport: 'Main Report',
  detailedReport: 'Detailed Report',
  industryConcentrationReport: 'Industry Concentration Report',
  reportCharts: 'Report Charts',
};
export default {
  customerFormPage,
  requestFormPage,
  investigationFormPage,
  facilitiesPage,
  guaranteePage,
  creditPage,
  riskPage,
  creditMemoPage,
  months,
  messages,
  customers,
  primevueConfig,
  mainReport,
  detailedReport,
  industryConcentrationReport,
  headerCards,
  riskMemoForm,
  orrForm,
  projectionPage,
  dashboardPage,
  requestForm,
  cashflowPage,
  microCreditPage,
  incomeStatementForm,
  cashflowForm,
  requiredDocuments,
  committeePage,
  requestMonitor,
  timeLine,
  reportCenter,
  charts,
  guaranteeForm,
  facilitiesDetails,
  sidebar,
};
